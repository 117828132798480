import { IUnitDetailsDto, RolesType, UnitDetailsDto } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { createFavourite, deleteFavourite } from 'state/ducks/favourite-unit/operations';
import { getFilterOptions } from 'state/ducks/filter-search/operations';
import {
	UpdateIsFavourite,
	UpsertMachineDetails,
	UpsertMachineDetailsBasicUser,
} from 'state/ducks/machine-details/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { LabelValuePair } from 'types/label-value-pair';
import { images } from 'utilities/images';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import { biConvertNumberToSeparatedString } from 'utilities/number-helpers';
import { hasAtLeast, hasAtLeastPermissionSuperAdmin, isAtLeastCountryAdminFromState } from 'utilities/roles-util';
import { getEmailFromToken } from 'utilities/token-util';
import WhiteCard from 'view/shared/components/cards/white-card';
import { getDateToString } from 'view/shared/components/date-to-string';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	getLabelValueOrDefaultToLocalizedNoDataString,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import { LimitedDataLabel } from 'view/shared/components/limited-data-label';
import { cardDetailsStyles, cardGeneralClassNames } from '../constants';
import { ManualItem } from '../documents-helper';
import { DeviceDocumentButtons } from './device-document-buttons';
import './technical-details.scss';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const currMachine = getMachineFromIdDataMapOrDefault(
		state.machineDetailsReducer.machineDetails,
		ownProps.machineDetailsId,
		UnitDetailsDto
	);

	const userGroups = state.groupsReducer.groups.find(g => g.id === getEmailFromToken());
	const userRole =
		userGroups?.data.find(g => g.id === currMachine.data.ownerGroupId)?.roleType ?? RolesType.DataOutOfRange;
	const isCountryAdmin = isAtLeastCountryAdminFromState(state, currMachine.data.ownerGroupId);
	const isSuperAdmin = state.userPermissionReducer.UserGroupPermissions.some(ugp =>
		hasAtLeastPermissionSuperAdmin(ugp.roleType)
	);

	return {
		machineDetailsDto: currMachine.data,
		pickupSystem: state.machineDetailsReducer.options.pickupSystems,
		manufacturer: state.machineDetailsReducer.options.manufacturers,
		unitModels: state.machineDetailsReducer.options.unitModels,
		unitTypes: state.machineDetailsReducer.options.unitTypes,
		wasteTypes: state.machineDetailsReducer.options.wasteTypes,
		containerSizes: state.machineDetailsReducer.options.containerSizes,
		filterOptions: state.filterSearchReducer.filterOptions,
		unitModelId: currMachine.data.unitModelId,
		unitTypeId: currMachine.data.unitTypeId,
		wasteTypeId: currMachine.data.wasteTypeId,
		lastestChange: currMachine.data.lastestChange,
		containerSize: currMachine.data.containerSize,
		manufacturerId: currMachine.data.manufacturerId!,
		pickupSystemId: currMachine.data.unitPickupSystemId,
		totalHourCount: currMachine.data.totalRunningHours,
		totalNumberOfCycles: currMachine.data.totalNumberOfCycles,
		cyclesSinceLastOutput: currMachine.data.cyclesSinceLastOutput,
		totalNumberOfpressSeries: currMachine.data.totalNumberOfPressSeries,
		pressSeriesSinceLastOutput: currMachine.data.pressSeriesSinceLastOutput,
		unitTracker: state.deviceTrackerReducer.unitTrackers.find(tracker => tracker.unitId === currMachine.data.id),
		devicePhoneNo: currMachine.data.devicePhoneNo,
		machineGroupsName: currMachine.data.unitTypeName,
		ownerGroupId: currMachine.data.ownerGroupId,
		pricePerEmptying: currMachine.data.pricePerEmptying,
		pricePerEmptyingCurrency: currMachine.data.pricePerEmptyingCurrency,
		optimizationProjectEnabledFor: currMachine.data.optimizationProjectEnabledFor,
		isFavourite: currMachine.data.isFavourite,
		isCountryAdmin,
		isSuperAdmin,
		userRole,
		emptyCounter: currMachine.data.emptyCounter,
		showLimitedData: currMachine.data.hasSubscription || isSuperAdmin,
		showLimitedDataCountryAdmin: currMachine.data.hasSubscription || isCountryAdmin,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	UpsertMachineDetails: async (unitDetail: IUnitDetailsDto) => (await UpsertMachineDetails(unitDetail))(dispatch),
	UpsertMachineDetailsBasicUser: async (unitDetail: IUnitDetailsDto) =>
		(await UpsertMachineDetailsBasicUser(unitDetail))(dispatch),
	getFilterOptions: async () => (await getFilterOptions())(dispatch),
	createFavourite: async (unitDetail: IUnitDetailsDto) => {
		(await createFavourite(unitDetail.id))(dispatch);
		UpdateIsFavourite(unitDetail, true)(dispatch);
	},
	deleteFavourite: async (unitDetail: IUnitDetailsDto) => {
		(await deleteFavourite(unitDetail.id))(dispatch);
		UpdateIsFavourite(unitDetail, false)(dispatch);
	},
});

interface AppProps {
	machineDetailsId: string;
	isCountryAdmin: boolean;
	isAdmin: boolean;
	handleVideoBtnPressed: (videoName: string) => void;
	manualItems: ManualItem[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

class TechnicalDetails extends React.PureComponent<Props> {
	public render() {
		const technicalDetailsPairValues = this.getTechnicalDetailsPairValues();
		const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer}`;

		return (
			<WhiteCard title={localized('TechnicalDetails')} classNameContainer={containerClassName}>
				<LabelValueCard
					labels={technicalDetailsPairValues.labels}
					values={technicalDetailsPairValues.valuesForLabels}
				/>

				<DeviceDocumentButtons
					machineDetailsId={this.props.machineDetailsId}
					handleVideoBtnPressed={this.props.handleVideoBtnPressed}
					manualItems={this.props.manualItems}
				/>
			</WhiteCard>
		);
	}

	public async componentDidMount() {
		if (!this.props.filterOptions.machineTypeOptions.length) {
			await this.props.getFilterOptions();
		}

		this.setState({ selectedGroupNameId: this.props.ownerGroupId });
	}

	private async saveItem(key: keyof UnitDetailsDto, value: any) {
		let machineDetailsDto = { ...this.props.machineDetailsDto } as IUnitDetailsDto;
		if (key === 'unitModelId' && this.props.unitModels) {
			const unitModel = this.props.unitModels.find(unitModel => unitModel.id === value);
			if (unitModel) {
				machineDetailsDto.manufacturerId = unitModel.unitManufacturerId;
				machineDetailsDto.unitTypeId = unitModel.unitTypeId;
			}
		}
		machineDetailsDto[key] = value;
		await this.props.UpsertMachineDetails(machineDetailsDto);
	}

	/**
	 * Only set values which a basic user can set. Otherwise, the API can throw an auth exception
	 * @param key
	 * @param value
	 */
	private async saveItemBasicUser(key: keyof UnitDetailsDto, value: any, valueType: 'string' | 'number' = 'string') {
		let machineDetailsDto = { ...this.props.machineDetailsDto } as IUnitDetailsDto;

		if (valueType === 'number') {
			const numberValue = Number(value);

			if (isNaN(numberValue)) {
				notificationService.showWarningMessage(localized('TheInputIsInvalid'));
				return;
			}
		} else if (typeof value !== valueType) {
			notificationService.showWarningMessage(localized('TheInputIsInvalid'));
			return;
		}

		machineDetailsDto[key] = value;
		await this.props.UpsertMachineDetailsBasicUser(machineDetailsDto);
	}

	private getUnitTypeText() {
		let unitTypeText = ' ';
		if (this.props.unitTypes != null) {
			const unitType = this.props.unitTypes.find(a => a.id === this.props.unitTypeId);
			if (unitType) {
				unitTypeText = unitType.nameTranslationKey!;
			}
		}
		return unitTypeText;
	}
	private getManufacturerText() {
		let manufacturerText = ' ';
		if (this.props.manufacturer != null) {
			const manufacturer = this.props.manufacturer.find(a => a.id === this.props.manufacturerId);
			if (manufacturer) {
				manufacturerText =
					manufacturer.name! !== 'Other Manufacturer'
						? manufacturer.name!
						: localizedDynamic('otherManufacturer');
			}
		}

		return manufacturerText;
	}

	private toggleIsFavourite = () => {
		this.props.isFavourite
			? this.props.deleteFavourite(this.props.machineDetailsDto)
			: this.props.createFavourite(this.props.machineDetailsDto);
	};

	private getTechnicalDetailsPairValues(): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		let {
			wasteTypeSelected,
			wasteTypesOptions,
		}: { wasteTypeSelected: any; wasteTypesOptions: any[] } = this.getWasteTypeSelected();
		let {
			pickupSystemSelected,
			pickupSystemOptions,
		}: { pickupSystemSelected: any; pickupSystemOptions: any[] } = this.getPickupSystem();
		let {
			unitModelSelected,
			unitModelOptions,
		}: { unitModelSelected: any; unitModelOptions: any[] } = this.getUnitModel();
		let {
			containerSizeSelected,
			containerSizeOptions,
		}: { containerSizeSelected: any; containerSizeOptions: any[] } = this.getContainerSize();
		const {
			selected: optimizationProjectRoleSelected,
			options: optimizationProjectRoleOptions,
		} = this.getOptimizationProjectRole();

		let unitTypeText = localizedDynamic(this.getUnitTypeText());
		let manufacturerText = this.getManufacturerText();
		const favouriteClassNames: string = 'row-icon ' + (this.props.isCountryAdmin ? 'cursor-pointer' : '');

		addTranslationLabelValueMapToList(
			'Favourite',
			this.props.isFavourite ? (
				<img
					className={favouriteClassNames}
					src={images.favoriteFilled}
					alt={localized('Favourite')}
					onClick={this.toggleIsFavourite}
				/>
			) : (
				<img
					className={favouriteClassNames}
					src={images.favoriteOutline}
					alt={localized('Favourite')}
					onClick={this.toggleIsFavourite}
				/>
			),
			retMap
		);
		addTranslationLabelValueMapToList(
			'unitId',
			getLabelValueOrDefaultToLocalizedNoDataString(this.props.machineDetailsId),
			retMap
		);
		addTranslationLabelValueMapToList(
			'unitModel',
			getLabelValueOrDefaultToLocalizedNoDataString(unitModelSelected.label), // REMEMBER TO EDIT THIS WHEN ACTUAL FUNCTIONALITY IS ADDED (only display the machineType from Props...))
			retMap,
			this.props.showLimitedData && this.props.isCountryAdmin,
			value => this.saveItem('unitModelId', value),
			'dropdown',
			unitModelSelected.value, // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
			unitModelOptions // What can be picked?
			// Actual state (could be more than 1 selectable if necessary)
		);
		addTranslationLabelValueMapToList(
			'containerSize',
			<LimitedDataLabel
				valueForLabel={getLabelValueOrDefaultToLocalizedNoDataString(containerSizeSelected.label)}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && this.props.isAdmin,
			value => this.saveItem('containerSize', value),
			'dropdown',
			containerSizeSelected.value, // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
			containerSizeOptions // What can be picked?
			// Actual state (could be more than 1 selectable if necessary)
		);
		addTranslationLabelValueMapToList(
			'wasteType',
			<LimitedDataLabel
				valueForLabel={getLabelValueOrDefaultToLocalizedNoDataString(wasteTypeSelected.label)}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && this.props.isAdmin,
			value => this.saveItem('wasteTypeId', value),
			'dropdown',
			wasteTypeSelected.value, // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
			wasteTypesOptions // What can be picked?
			// Actual state (could be more than 1 selectable if necessary)
		);

		addTranslationLabelValueMapToList(
			'lastestChange',
			<LimitedDataLabel
				valueForLabel={this.props.lastestChange ? getDateToString(this.props.lastestChange) : ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && this.props.isCountryAdmin,
			value => this.saveItem('lastestChange', value),
			'datePicker',
			this.props.lastestChange
		);
		addTranslationLabelValueMapToList(
			'pickupSystem',
			<LimitedDataLabel
				valueForLabel={getLabelValueOrDefaultToLocalizedNoDataString(pickupSystemSelected.label)}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && this.props.isAdmin,
			value => this.saveItem('unitPickupSystemId', value),
			'dropdown',
			pickupSystemSelected.value, // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
			pickupSystemOptions // What can be picked?
		);
		addTranslationLabelValueMapToList(
			'unitsEmptied',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.emptyCounter)}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		addTranslationLabelValueMapToList(
			'totalRunningHours',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.totalHourCount) || ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		addTranslationLabelValueMapToList(
			'totalNumberOfCycles',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.totalNumberOfCycles) || ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		addTranslationLabelValueMapToList(
			'cyclesSinceLastOutput',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.cyclesSinceLastOutput) || ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		addTranslationLabelValueMapToList(
			'pressSeries',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.totalNumberOfpressSeries) || ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		addTranslationLabelValueMapToList(
			'pressSeriesSinceLastOutput',
			<LimitedDataLabel
				valueForLabel={biConvertNumberToSeparatedString(this.props.pressSeriesSinceLastOutput) || ''}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap
		);
		this.props.unitTracker &&
			addTranslationLabelValueMapToList(
				'trackerBatteryLevel',
				<LimitedDataLabel
					valueForLabel={
						this.props.unitTracker.batteryStatus
							? `${biConvertNumberToSeparatedString(this.props.unitTracker.batteryStatus)} %`
							: '-'
					}
					showLimitedData={this.props.showLimitedData}
				/>,
				retMap
			);
		addTranslationLabelValueMapToList(
			'devicePhoneNo',
			<LimitedDataLabel
				valueForLabel={getLabelValueOrDefaultToLocalizedNoDataString(this.props.devicePhoneNo)}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && this.props.isAdmin,
			value => this.saveItem('devicePhoneNo', value)
		);
		addTranslationLabelValueMapToList(
			'unitType',
			getLabelValueOrDefaultToLocalizedNoDataString(unitTypeText),
			retMap
		);
		addTranslationLabelValueMapToList(
			'manufacturer',
			getLabelValueOrDefaultToLocalizedNoDataString(manufacturerText),
			retMap
		);
		addTranslationLabelValueMapToList(
			'PricePerEmptying',
			<LimitedDataLabel
				valueForLabel={this.props.pricePerEmptying}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && hasAtLeast(this.props.userRole, this.props.optimizationProjectEnabledFor),
			value => this.saveItemBasicUser('pricePerEmptying', value, 'number')
		);
		addTranslationLabelValueMapToList(
			'PricePerEmptyingCurrency',
			<LimitedDataLabel
				valueForLabel={this.props.pricePerEmptyingCurrency}
				showLimitedData={this.props.showLimitedData}
			/>,
			retMap,
			this.props.showLimitedData && hasAtLeast(this.props.userRole, this.props.optimizationProjectEnabledFor),
			value => this.saveItemBasicUser('pricePerEmptyingCurrency', value)
		);
		this.props.isCountryAdmin &&
			addTranslationLabelValueMapToList(
				'OptimizationProjectEnabledFor',
				getLabelValueOrDefaultToLocalizedNoDataString(optimizationProjectRoleSelected.label),
				retMap,
				this.props.isSuperAdmin ||
					(this.props.showLimitedData &&
						this.props.isCountryAdmin &&
						optimizationProjectRoleSelected.value !== RolesType.SuperAdmin.toString()),
				value => this.saveItem('optimizationProjectEnabledFor', value),
				'dropdown',
				optimizationProjectRoleSelected.value, // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
				optimizationProjectRoleOptions // What can be picked?
			);

		return retMap;
	}

	private getPickupSystem() {
		let pickupSystemOptions: any[] = [];
		this.props.pickupSystem &&
			this.props.pickupSystem.forEach(element => {
				pickupSystemOptions.push({
					label: element.nameTranslationKey ? localizedDynamic(element.nameTranslationKey) : '',
					value: element.id,
				});
			});
		let pickupSystemSelected = pickupSystemOptions.find(a => a.value === this.props.pickupSystemId);
		if (!pickupSystemSelected) {
			pickupSystemSelected = { label: ' ', value: ' ' };
		}
		return { pickupSystemSelected, pickupSystemOptions };
	}

	private getWasteTypeSelected() {
		let wasteTypesSelectedOptions: any[] = [];
		this.props.wasteTypes &&
			this.props.wasteTypes.forEach(element => {
				wasteTypesSelectedOptions.push({
					label: element.nameTranslationKey ? localizedDynamic(element.nameTranslationKey) : '',
					value: element.id,
					isDeleted: element.isDeleted,
				});
			});
		let wasteTypeSelected = wasteTypesSelectedOptions.find(a => a.value === this.props.wasteTypeId);
		if (!wasteTypeSelected) {
			wasteTypeSelected = { label: ' ', value: ' ' };
		}
		let wasteTypesOptions = wasteTypesSelectedOptions
			.filter(e => !e.isDeleted)
			.sort((a, b) => (a.label > b.label ? 1 : -1));
		return { wasteTypeSelected, wasteTypesOptions };
	}

	private getUnitModel() {
		let unitModelOptions: LabelValuePair<string | number>[] = [];
		this.props.unitModels &&
			this.props.unitModels.forEach(element => {
				unitModelOptions.push({ label: element.name ? localizedDynamic(element.name) : '', value: element.id });
			});
		let unitModelSelected = unitModelOptions.find(a => a.value === this.props.unitModelId);
		if (!unitModelSelected) {
			unitModelSelected = { label: ' ', value: ' ' };
		}

		return { unitModelSelected, unitModelOptions };
	}

	private getContainerSize() {
		let containerSizeOptions: LabelValuePair<string | number>[] = [];
		let currUnitModelName: string | undefined = this.props.unitModels?.find(
			model => model.id === this.props.unitModelId
		)?.name;
		this.props.containerSizes
			?.find(size => size.machineName === currUnitModelName)
			?.containerSize?.sort((a, b) => a - b)
			.forEach(size => {
				containerSizeOptions.push({ label: size + ' m3', value: size.toString() });
			});
		let containerSizeSelected = containerSizeOptions.find(a => a.value === this.props.containerSize.toString());
		if (!containerSizeSelected) {
			containerSizeSelected = { label: ' ', value: ' ' };
		}

		return { containerSizeSelected, containerSizeOptions };
	}

	private getOptimizationProjectRole() {
		const options: LabelValuePair<string | number>[] = [
			{
				label: localized('BasicUser'),
				value: RolesType.BasicUser.toString(),
			},
			{
				label: localized('Admin'),
				value: RolesType.Admin.toString(),
			},
			{
				label: localized('CountryAdmin'),
				value: RolesType.CountryAdmin.toString(),
			},
			{
				label: localized('superAdmin'),
				value: RolesType.SuperAdmin.toString(),
			},
		].sort((a, b) => Number(a.value) - Number(b.value));

		const selected = options.find(x => x.value === this.props.optimizationProjectEnabledFor?.toString()) ?? {
			label: ' ',
			value: ' ',
		};

		return { selected, options };
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalDetails);
