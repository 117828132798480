import { UnitDetailsDto } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { ErrorIdKey, getLocalizedUnitStatusErrorLong } from 'utilities/machine-error-util';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import WhiteCard from 'view/shared/components/cards/white-card';
import { TempSubscriptionButton } from 'view/shared/components/temp-subscription-button';
import { JsxIconAndType } from 'view/shared/components/unit-status/constants';
import { getUnitStatusIconForControllerWithErrors } from 'view/shared/components/unit-status/unit-status-icon-util';
import { getIcon } from 'view/shared/utils/icon-helper';
import './notification-card.scss';

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const currMachine = getMachineFromIdDataMapOrDefault(
		state.machineDetailsReducer.machineDetails,
		ownProps.machineDetailsId,
		UnitDetailsDto
	);

	return {
		controller: currMachine.data.controllerName,
		errorAndSeverities: currMachine.data.errorAndSeverities,
		machineGroupsName: currMachine.data.unitTypeName,
	};
};

interface AppProps {
	machineDetailsId: string;
}

type Props = ReturnType<typeof mapStateToProps> & AppProps;

const containerClassName = `margin-right-14px flex-fill-remaining-space 
    card-max-width-700-and-margin-spacing flex-direction-column position-relative`;
const textContainerClassName = 'flex-center-column margin-bottom-1em';
const textClassName = 'margin-left-10px';
const noNotificationsContent = (
	<div className={textContainerClassName}>
		{getIcon('check-ok')}
		<span className={textClassName}>{localized('NothingToReport')}</span>
	</div>
);

class NotificationsCard extends React.PureComponent<Props> {
	public render() {
		const notifications: React.ReactNode[] = [];
		const relevantErrors = this.props.errorAndSeverities || [];
		relevantErrors.sort((a, b) => {
			let aSeverity = a.severity ?? Number.MAX_SAFE_INTEGER;
			let bSeverity = b.severity ?? Number.MAX_SAFE_INTEGER;

			return aSeverity - bSeverity;
		});

		relevantErrors.forEach(val => {
			const iconAndType = getUnitStatusIconForControllerWithErrors(val, false);

			notifications.push(this.getUnitStatusIconAndDescription(val.readableError!, iconAndType));
		});

		return (
			<WhiteCard
				title={localized('ActiveWarningsAndFailures')}
				classNameContainer={containerClassName}
				topRightElement={
					<TempSubscriptionButton
						className="notification-card-temp-subscription-icon"
						machineDetailsId={this.props.machineDetailsId}
					/>
				}
			>
				{notifications.length ? notifications : noNotificationsContent}
			</WhiteCard>
		);
	}

	private getUnitStatusIconAndDescription(readableError: string, iconAndType: JsxIconAndType): React.ReactNode {
		return (
			<div
				key={`${this.props.machineDetailsId}_${this.props.controller || ''}_${readableError}`}
				className={textContainerClassName}
			>
				{iconAndType.icon}
				<span className={textClassName}>{getLocalizedUnitStatusErrorLong(readableError as ErrorIdKey)}</span>
			</div>
		);
	}
}

export default connect(mapStateToProps, {})(NotificationsCard);
