import { getArgsAsTypesafeObjectProperties } from '../constants-util';

export const constants = getArgsAsTypesafeObjectProperties(
    'GET_MACHINE_DETAILS',
    'UPDATE_UNIT_GROUP',
    'PUT_MACHINE_DETAILS',
    'GET_DROPDOWN_OPTIONS',
    'UPDATE_MACHINE_WEIGHING_SERIAL_NUMBER',

    // Baler functions
    'UPSERT_BALE_STORAGE',

    // Favourite
    'UPDATE_IS_FAVOURITE',

    // Subscription
    'SET_TEMPORARY_SUBSCRIPTION',
);
