import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IFilterDto, INotificationDefinitionDto } from 'api/api';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import {
	deleteNotificationDefinition,
	getNotificationDefinitions,
	navigateToSpecificNotification,
	removeTemporaryNotificationDefinition,
} from 'state/ducks/notifications/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { Spinner } from 'view/components/spinner/spinner';
import Accordion from 'view/shared/components/bi-accordion/accordion';
import NotificationAccordionContent from './notification-accordion-content';
import NotificationAccordionHeader from './notification-accordion-header';
import './notifications-list.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import warning from 'assets/warning-icon.svg';

const buttonClassName = 'p-button-custom bi-button-icon-only ';
export const classNameCardContainer = 'width-100-percent padding-vertical-0-5 ';

const mapStateToProps = (state: AppState) => {
	return {
		notificationDefinitions: state.notificationsReducer.notificationDefinitions,
		selectedMachines: state.machineSelectedReducer.machinesSelected, //Needed to rerender accordion. Used in notification-helper.ts
		sorting: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.sorting,
		userSettings: state.userSettingReducer.userSettings,
		loading: state.notificationsReducer.loading,
		appliedFilter: state.filterSearchReducer.appliedFilters,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	deleteNotificationDefinition: (notDef: INotificationDefinitionDto) =>
		deleteNotificationDefinition(notDef)(dispatch),
	getNotificationDefinitions: (filter: IFilterDto) => getNotificationDefinitions(filter)(dispatch),
	removeTemporaryNotificationDefinition: () => removeTemporaryNotificationDefinition()(dispatch),
	navigateToSpecificNotification: (id?: number) => navigateToSpecificNotification(id, true)(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NotificationsList extends React.PureComponent<Props> {
	public componentDidMount() {
		this.getNotificationDefinitions();
	}

	public componentDidUpdate(preProps: Props) {
		if (preProps.appliedFilter !== this.props.appliedFilter) this.getNotificationDefinitions();
	}

	public render() {
		let defs = this.props.notificationDefinitions.map(def => {
			return {
				header: (
					<NotificationAccordionHeader
						def={def}
						buttonsGenerator={this.buttonsGenerator}
						handleEdit={this.handleClickEditBtn}
						handleDelete={this.handleClickDeleteBtn}
					/>
				),
				content: (
					<NotificationAccordionContent
						def={def}
						buttonsGenerator={this.buttonsGenerator}
						handleEdit={this.handleClickEditBtn}
						handleDelete={this.handleClickDeleteBtn}
					/>
				),
			};
		});

		return (
			<div className="margin-left-3 margin-right-3 margin-vertical-20px notifications-list">
				<h3>{localized('Notifications')}</h3>
				<br />

				{this.props.notificationDefinitions?.length ? (
					<>
						<div className="accordion-grid padding-left-32px padding-right-32px margin-bottom-1em">
							<b className="word-break">{localized('NotificationName')}</b>
							<div className="mini-grid">
								<b className="word-break">{localized('ControllerTypes')}</b>
								<b className="word-break">{localized('Notifications')}</b>
							</div>
							<b>{localized('Users')}</b>
							<b>{localized('CreatedBy')}</b>
							<b>{localized('LastModifiedOn')}</b>
							<b>{localized('ModifiedBy')}</b>
							<b className="text-right word-break">{localized('Action')}</b>
						</div>

						<Accordion sections={defs} />
					</>
				) : (
					this.props.loading && <Spinner shouldOverlay={false}></Spinner>
				)}
			</div>
		);
	}

	private getNotificationDefinitions = () => {
		this.props.getNotificationDefinitions(this.props.appliedFilter);
	};

	private handleClickDeleteBtn = (def: INotificationDefinitionDto) => {
		this.props.deleteNotificationDefinition(def);
	};

	private handleClickEditBtn = (def: INotificationDefinitionDto) => {
		this.props.navigateToSpecificNotification(def.id);
	};

	private buttonsGenerator = (
		def: INotificationDefinitionDto,
		handleEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
		handleDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	): JSX.Element => {
		return (
			<div className="flex-1 text-right">
				{def.hasOfflineMachines && (
					<BiTooltip overlay={localized('UsedByOfflineMachines')} noNesting={true}>
						<img src={warning} alt="warning" className="warning-icon" />
					</BiTooltip>
				)}
				<Button
					tooltip={def.isEditAllowed ? localized('Edit') : localized('PermissionRequired')}
					className={buttonClassName}
					icon="pi pi-pencil"
					onClick={handleEdit}
					disabled={!def.isEditAllowed}
				/>
				<Button
					tooltip={def.isEditAllowed ? localized('Delete') : localized('PermissionRequired')}
					className={buttonClassName}
					icon="pi pi-trash"
					onClick={handleDelete}
					disabled={!def.isEditAllowed}
				/>
			</div>
		);
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, NotificationsList, 'NotificationsList'));
