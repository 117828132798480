import { connect } from 'react-redux';
import React from 'react';
import { Dispatch } from 'redux';
import { getLanguage } from 'state/i18n';
import FilterDateBar from 'view/components/filter-date-bar/filter-date-bar';
import HistoryTableWrapper from '../../bi-table/history-table-wrapper';
import FilterCheckboxes from '../filter-checkboxes/filter-checkboxes';
import { GetExportButton } from 'view/shared/components/export-button';
import { unitStateHistoryExcelClient } from 'state/ducks/api-clients';
import { MimeTypes } from 'utilities/mime-type-helper';
import fileSaver from 'file-saver';
import { notificationService } from 'services/notification-service';
import { clearUnitHistory, getUnitHistoryFilteredByDates } from 'state/ducks/machine-history/operations';
import './history-table-with-filter.scss';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	clearUnitHistory: (id: string) => clearUnitHistory(id)(dispatch),
	getUnitHistoryFiltered: async (id: string, startDate?: Date, endDate?: Date) =>
		(await getUnitHistoryFilteredByDates(id, startDate, endDate))(dispatch),
});

interface PropsFromParent {
	initialStartDate?: Date;
	initialEndDate?: Date;
	machineId: string;
	isBaler: boolean;
}

interface State {
	filterOnFillLevelChanged: boolean;
	filterOnFull: boolean;
	isLoading?: boolean;
	isDownloading: boolean;
	startDate?: Date;
	endDate?: Date;
}

type Props = ReturnType<typeof mapDispatchToProps> & PropsFromParent;

class HistoryTableWithFilter extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			filterOnFillLevelChanged: false,
			filterOnFull: false,
			isDownloading: false,
		};
	}

	public render() {

		return (
			<>
				<div className="flexDirectionRow padding-2">
					<FilterDateBar
						idOfFilterDateBarParent="history"
						handleFilterByDates={this.handleFilterByDatesUnitHistory}
						initialStartDate={this.props.initialStartDate}
						initialEndDate={this.props.initialEndDate}
					/>
					<GetExportButton
						handleExportExcel={this.handleExportExcel}
						loadingState={this.state.isDownloading}
					/>
					<FilterCheckboxes
						setFillLevelFilterState={this.updateFilterByFillLevel}
						setEmptiedFilterState={this.updateFilterByFull}
						isBaler={this.props.isBaler}
					/>
				</div>

				<HistoryTableWrapper
					isLoading={this.state.isLoading}
					machineId={this.props.machineId}
					filterOnFillLevelChanged={this.state.filterOnFillLevelChanged}
					filterOnFull={this.state.filterOnFull}
					isBaler={this.props.isBaler}
				/>
			</>
		);
	}

	private handleExportExcel = async () => {
		this.setState({ isDownloading: true });
		try {
			const fileResponse = await unitStateHistoryExcelClient.downloadFileByMachineIds(
				this.props.machineId,
				this.state.startDate ? this.state.startDate : this.props.initialStartDate,
				this.state.endDate ? this.state.endDate : this.props.initialEndDate,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				this.state.filterOnFillLevelChanged,
				this.state.filterOnFull,
				getLanguage()
			);

			if (fileResponse) {
				const blob = new Blob([fileResponse.data], {
					type: MimeTypes.ContentType.xlsx,
				});
				fileSaver.saveAs(blob, `Report-Table.${MimeTypes.Extensions.xlsx}`);
			}
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		} finally {
			this.setState({ isDownloading: false });
		}
	};

	private handleFilterByDatesUnitHistory = async (startDate?: Date, endDate?: Date) => {
		this.setState({ startDate: startDate, endDate: endDate });
		this.props.clearUnitHistory(this.props.machineId);

		this.setState({ isLoading: true });

		await this.props.getUnitHistoryFiltered(this.props.machineId, startDate, endDate);

		this.setState({ isLoading: false });
	};

	private updateFilterByFillLevel = (isChecked: boolean) => {
		this.setState({ filterOnFillLevelChanged: isChecked });
	};

	private updateFilterByFull = (isChecked: boolean) => {
		this.setState({ filterOnFull: isChecked });
	};
}

export default connect(null, mapDispatchToProps)(HistoryTableWithFilter);
