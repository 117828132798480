import React from 'react';
import {
	FaCaretDown,
	FaClock,
	FaEnvelopeOpenText,
	FaEquals,
	FaExclamationTriangle,
	FaFile,
	FaFileCsv,
	FaFileExcel,
	FaFileImage,
	FaFilePdf,
	FaFilePowerpoint,
	FaFileWord,
	FaFolder,
	FaGreaterThan,
	FaImage,
	FaInfoCircle,
	FaLessThan,
	FaPencilAlt,
	FaPlus,
	FaQuestionCircle,
	FaRedo,
	FaRss,
	FaTimesCircle,
	FaTools,
	FaVideo,
	FaRegHandshake,
} from 'react-icons/fa';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IconBaseProps } from 'react-icons/lib/cjs/iconBase';
import {
	MdFileDownload,
	MdGpsFixed,
	MdHistory,
	MdPrint,
	MdSignalWifi0Bar,
	MdSignalWifi1Bar,
	MdSignalWifi2Bar,
	MdSignalWifi3Bar,
	MdSignalWifi4Bar,
	MdSignalWifiOff,
} from 'react-icons/md';
import { BrandName, IconDefaultSize, IconWifiSignalSize } from 'utilities/constants';
import { images } from 'utilities/images';
import { BrandBramidan } from 'view/components/navigation-bar/brand-details';
import { FileTypes } from './file-type-helper';
import './icon-helper.scss';

enum SignalPct {
	// The values are up to - i.e. 1 bar up to 25%
	NO_BARS = 0,
	BAR_1 = 25,
	BAR_2 = 50,
	BAR_3 = 75,
	BAR_4 = 100,
}

type balerIconSizes = 'icon' | 'big';

export function getBalerIcon(size: balerIconSizes) {
	if (BrandName === BrandBramidan) {
		if (size === 'big') {
			return images.balerBigBramidan;
		}
		return images.balerIconBramidan;
	} else {
		// Not Bramidan? Then it must be (or default to) Presto
		// if (size === "big") { return balerBigPresto; }
		return images.balerIconPresto;
	}
}

type supportedIcons =
	| 'check-ok'
	| 'triangle-warning'
	| 'cross-error'
	| 'envelope-message'
	| 'info-circle'
	| 'history'
	| 'file-download'
	| 'caret-down'
	| 'clock-12-20'
	| 'rss-wifi'
	| 'gps-crosshair-fixed'
	| 'help-question'
	| 'rssi-wifi-0'
	| 'rssi-wifi-1'
	| 'rssi-wifi-2'
	| 'rssi-wifi-3'
	| 'rssi-wifi-4'
	| 'rssi-wifi-slash'
	| 'print'
	| 'plus'
	| 'pdf'
	| 'image'
	| 'video'
	| 'pencil'
	| 'greater-than'
	| 'equal-to'
	| 'less-than'
	| 're-occuring'
	| 'folder'
	| 'file'
	| 'image-file'
	| 'csv'
	| 'excel'
	| 'powerpoint'
	| 'word'
	| 'service'
	| 'handshake';

export type MachineStatus = 'Online' | 'Offline' | 'Failure' | 'Warning' | 'No modem' | 'No Error';

export function getMarkerColorByOnlineStatus(status?: MachineStatus) {
	switch (status) {
		case 'Online':
			return images.map_marker_nomodem;
		case 'Offline':
			return images.map_marker_nomodem;
		case 'Warning':
			return images.map_marker_nomodem;
		case 'No modem':
			return images.map_marker_nomodem;
		case 'Failure':
			return images.map_marker_nomodem;
		default:
			return images.map_marker_nomodem;
	}
}

export const getIcon = (name: supportedIcons, size?: number, color?: string, className?: string): JSX.Element => {
	const classNameDefault = `${className || ''}`;
	const sizeOrIconDefault = size || IconDefaultSize;
	const sizeOrWifiDefault = size || IconWifiSignalSize;
	const props: IconBaseProps = { size: sizeOrIconDefault, color, className };
	const propsWithoutSize: IconBaseProps = { color, className };

	switch (name) {
		case 'check-ok':
			return (
				<IoMdCheckmarkCircle
					size={sizeOrIconDefault}
					color={color}
					className={`${classNameDefault} color-green-light`}
				/>
			);
		case 'triangle-warning':
			return (
				<FaExclamationTriangle
					size={sizeOrIconDefault}
					color={color}
					className={`${classNameDefault} color-warning-orange`}
				/>
			);
		case 'cross-error':
			return (
				<FaTimesCircle
					size={sizeOrIconDefault}
					color={color}
					className={`${classNameDefault} color-error-red`}
				/>
			);
		case 'envelope-message':
			return (
				<FaEnvelopeOpenText
					size={sizeOrIconDefault}
					color={color}
					className={`${classNameDefault} color-info-blue`}
				/>
			);
		case 'info-circle':
			return <FaInfoCircle {...props} />;
		case 'greater-than':
			return <FaGreaterThan {...props} />;
		case 'equal-to':
			return <FaEquals {...props} />;
		case 'less-than':
			return <FaLessThan {...props} />;
		case 're-occuring':
			return <FaRedo {...props} />;
		case 'history':
			return <MdHistory {...props} />;
		case 'file-download':
			return <MdFileDownload {...props} />;
		case 'caret-down':
			return <FaCaretDown {...props} />;
		case 'clock-12-20':
			return <FaClock {...props} />;
		case 'rss-wifi':
			return <FaRss {...props} />;
		case 'gps-crosshair-fixed':
			return <MdGpsFixed {...props} />;
		case 'help-question':
			return <FaQuestionCircle {...props} color="rgb(254, 6, 6)" />;
		case 'rssi-wifi-slash':
			return <MdSignalWifiOff size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'rssi-wifi-0':
			return <MdSignalWifi0Bar size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'rssi-wifi-1':
			return <MdSignalWifi1Bar size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'rssi-wifi-2':
			return <MdSignalWifi2Bar size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'rssi-wifi-3':
			return <MdSignalWifi3Bar size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'rssi-wifi-4':
			return <MdSignalWifi4Bar size={sizeOrWifiDefault} {...propsWithoutSize} />;
		case 'print':
			return <MdPrint {...props} />;
		case 'plus':
			return <FaPlus {...props} />;
		case 'pdf':
			return <FaFilePdf {...props} />;
		case 'image':
			return <FaImage {...props} />;
		case 'video':
			return <FaVideo {...props} />;
		case 'pencil':
			return <FaPencilAlt {...props} />;
		case 'folder':
			return <FaFolder {...props} />;
		case 'file':
			return <FaFile {...props} />;
		case 'image-file':
			return <FaFileImage {...props} />;
		case 'csv':
			return <FaFileCsv {...props} />;
		case 'excel':
			return <FaFileExcel {...props} />;
		case 'powerpoint':
			return <FaFilePowerpoint {...props} />;
		case 'word':
			return <FaFileWord {...props} />;
		case 'service':
			return <FaTools {...props} />;
		case 'handshake':
			return <FaRegHandshake {...props} />;
	}
};

export const getIconByFileName = (fileType: FileTypes | undefined) => {
	switch (fileType) {
		case FileTypes.pdf:
			return getIcon('pdf');
		case FileTypes.svg:
		case FileTypes.png:
		case FileTypes.jpg:
		case FileTypes.jpeg:
		case FileTypes.bmp:
			return getIcon('image-file');
		case FileTypes.csv:
			return getIcon('csv');
		case FileTypes.xls:
			return getIcon('excel');
		case FileTypes.pptx:
			return getIcon('powerpoint');
		case FileTypes.doc:
		case FileTypes.docx:
			return getIcon('word');
		default:
			return getIcon('file');
	}
};

export function getWifiStrengthIcon(rssi: number, size: number = IconWifiSignalSize) {
	if (rssi === SignalPct.NO_BARS) {
		return <MdSignalWifi0Bar size={size} />;
	} else if (rssi <= SignalPct.BAR_1) {
		return <MdSignalWifi1Bar size={size} />;
	} else if (rssi <= SignalPct.BAR_2) {
		return <MdSignalWifi2Bar size={size} />;
	} else if (rssi <= SignalPct.BAR_3) {
		return <MdSignalWifi3Bar size={size} />;
	} else {
		return <MdSignalWifi4Bar size={size} />;
	}
}
