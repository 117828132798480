import React, { FC } from 'react';
import { LabelValuesJSX } from './label-value-card';
import { localized } from 'state/i18n';

interface Props {
	valueForLabel: LabelValuesJSX;
	showLimitedData?: boolean;
}

export const LimitedDataLabel: FC<Props> = ({ valueForLabel, showLimitedData }) => {
	return showLimitedData ? <>{valueForLabel}</> : <div className="disabled-text">{localized('NoSubscription')}</div>;
};
