import { RolesType } from "api/api";
import { BrandTypes } from "utilities/brand-utils";


export type AuthPageRequirement = {
    minRole?: RolesType;
    exactRoles?: RolesType[];
    isPartnerArea?: boolean;
    environmemtSpecific?: BrandTypes;
    blockIfNoSubscription?: boolean;
}

const basicUserRequirements: AuthPageRequirement = { minRole: RolesType.BasicUser };


export const profilePageAuthRequirements: AuthPageRequirement = {
    minRole: RolesType.NoUnits
}
export const administrationPageAuthRequirements: AuthPageRequirement = {
    minRole: RolesType.Admin
}
export const unitsPageAuthRequirements: AuthPageRequirement = basicUserRequirements
export const locationPageAuthRequirements: AuthPageRequirement = basicUserRequirements
export const reportPageAuthRequirements: AuthPageRequirement = basicUserRequirements
export const notificationPageAuthRequirements: AuthPageRequirement = basicUserRequirements

export const partnerAreaPageAuthRequirements: AuthPageRequirement = {
    exactRoles: [RolesType.SuperAdmin],
    isPartnerArea: true,
    environmemtSpecific: BrandTypes.Presto
}

export const unitHistoryAuthRequirements: AuthPageRequirement = {
    blockIfNoSubscription: true,
    minRole: RolesType.BasicUser
}
