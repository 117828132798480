export const RouteBasePath = '/';

export const unsubscribeRoutes = {
	userId: ':userId',
	path: '/notifications/unsubscribe/',
};
export const Routes = {
	Administration: '/administration',
	Profile: '/profile',
	SignIn: '/sign-in',
	SignInRedirect: '/sign-in-redirect',
	SignOutRedirect: '/sign-out-redirect',
	Callback: '/callback',
	Dashboard: '/dashboard',
	Location: '/location',
	Machines: '/machines',
	Reports: '/reports',
	Notifications: '/notifications',
	NotificationsEdit: '/notifications/edit',
	NotificationsEditWithParam: '/notifications/edit/:notification_definition_id',
	NotificationsUnsubscribeWithParam: unsubscribeRoutes.path + unsubscribeRoutes.userId,
	MachineDetails: '/machines/details',
	MachineDetailsWithParam: '/machines/details/:url_id',
	UnitHistory: '/machines/history',
	UnitHistoryWithParam: '/machines/history/:url_id/:tab_index?',
	PartnerArea: '/partner-area', 
	TermsAndconditions: '/terms-and-conditions',
} as const;

// TODO: DASHBOARD. Once dashboard is stable, add this back
// export const routesToDashboard = [RouteBasePath, Routes.Dashboard];
// export const routesToHomePage = [Routes.Machines, Routes.Location, Routes.Reports, Routes.Notifications];
// TODO: DASHBOARD. Remove the two lines below once dashboard is back
export const routesToDashboard = [];
export const routesToHomePage = [RouteBasePath, Routes.Machines, Routes.Location, Routes.Reports, Routes.Notifications, Routes.PartnerArea];
