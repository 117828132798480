import { UnitDetailsDto } from 'api/api';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import WhiteCard from 'view/shared/components/cards/white-card';
import { getDateToString } from 'view/shared/components/date-to-string';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import { cardDetailsStyles, cardGeneralClassNames } from '../constants';
import { ManualItem } from '../documents-helper';

interface AppProps {
	machineDetailsId: string;
	isCountryAdmin: boolean;
	isAdmin: boolean;
	handleVideoBtnPressed: (videoName: string) => void;
	manualItems: ManualItem[];
}

export const SubscriptionDetails: React.FC<AppProps> = props => {
	const machineDetails = useSelector((state: AppState) => state.machineDetailsReducer.machineDetails);
	const currentMachine = useMemo(
		() => getMachineFromIdDataMapOrDefault(machineDetails, props.machineDetailsId, UnitDetailsDto),
		[machineDetails, props.machineDetailsId]
	);
	const isSuperAdmin = useSelector(isAtLeastSuperAdminFromState);
	const showLimitedData = currentMachine.data.hasSubscription || isSuperAdmin;
	const mapping = useMemo((): LabelValueForCardMap => {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		addTranslationLabelValueMapToList(
			'subscription',

			currentMachine.data.tempSubscriptionEndDate && showLimitedData
				? localized('TemporaryAccess')
				: currentMachine.data.subscription,
			retMap
		);
		addTranslationLabelValueMapToList(
			'subscriptionLeasingBilling',
			currentMachine.data.subscriptionLeasingBilling,
			retMap
		);
		addTranslationLabelValueMapToList(
			'subscriptionStartDate',

			currentMachine.data.subscriptionStartDate ? getDateToString(currentMachine.data.subscriptionStartDate) : '',

			retMap
		);
		addTranslationLabelValueMapToList(
			'subscriptionNextBillingDate',

			currentMachine.data.subscriptionNextBillingDate
				? getDateToString(currentMachine.data.subscriptionNextBillingDate)
				: '',
			retMap
		);
		addTranslationLabelValueMapToList(
			'subscriptionEndDate',

			currentMachine.data.subscriptionEndDate ? getDateToString(currentMachine.data.subscriptionEndDate) : '',
			retMap
		);
		return retMap;
	}, [currentMachine, isSuperAdmin]);
	const containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer}`;

	return (
		<WhiteCard title={localized('SubscriptionDetails')} classNameContainer={containerClassName}>
			<LabelValueCard labels={mapping.labels} values={mapping.valuesForLabels} />
		</WhiteCard>
	);
};
