import { Sorting } from '@devexpress/dx-react-grid';
import { FilterDto, IFilterDto, KeyValuePairOfStringAndString } from 'api/api';
import { store } from 'index';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized, localizedDynamic } from 'state/i18n';
import { FilterOptions } from 'state/models/FilterOptions';
import { Action } from 'typescript-fsa';
import { VIRTUAL_PAGE_SIZE } from 'view/components/table/bi-table/bi-table';
import { FilterSelections } from '../../models/FilterSelections';
import { unitClient } from '../api-clients';
import { getMachinesSelected } from '../machine-selected/operations';
import { clearMachines, getMachineCount, getMachinesFiltered } from '../machines/operations';
import { clearReports, setDatatype } from '../reports/operations';
import { setSelectedMachines } from '../table-settings/operations';
import { GetFilterOptionsAction, SetAppliedFilterAction, SetFilterAction, SetUserAppliedFilterAction } from './actions';
import { initialFilterState } from './reducer';

export function applyFilter(
	filter: FilterSelections,
	sorting: Sorting[] | undefined,
	userAction: boolean,
	selectedMachines?: number[]
) {
	return async (dispatch: Dispatch) => {
		let filterDto = new FilterDto();
		filterDto.init(filter);
		if (filter.machineTypesSelected) {
			filterDto.machineTypesSelected = filter.machineTypesSelected.map(mts => mts.key!);
		}

		setDatatype(undefined)(dispatch);
		clearMachines()(dispatch);
		clearReports()(dispatch);

		if (userAction) {
			setUserAppliedFilter(filterDto)(dispatch);
		} else {
			setAppliedFilter(filterDto)(dispatch);
		}

		setSelectedMachines(selectedMachines ?? [])(dispatch);

		getMachineCount(filterDto)(dispatch);
		let machinesPromise = getMachinesFiltered(filterDto, 0, VIRTUAL_PAGE_SIZE, sorting && sorting[0])(dispatch);
		// Added to make sure we have all device-ids, mainly for select-all functionality
		getMachinesSelected(filterDto)(dispatch);

		await machinesPromise;
	};
}

// This is only done from code because a user can't set both a once
export function applyFilterAndSelection(filter: IFilterDto, selectedMachines?: number[]) {
	return async (dispatch: Dispatch) => {
		const filterOptions = store.getState().filterSearchReducer.filterOptions.machineTypeOptions;
		const sorting = store
			.getState()
			.tableSettingsReducer.tableSettingsList.find(
				ts => ts.tableSettings.id === store.getState().tableSettingsReducer.currentTableSettingId
			)?.sorting;

		let filterSelection = initialFilterState.filter;

		filterSelection = { ...filterSelection, ...filter, machineTypesSelected: [] };

		if (filter.machineTypesSelected?.length) {
			filterSelection.machineTypesSelected = filter.machineTypesSelected
				.map(machineType => {
					const filterOptionForCurrentType = filterOptions.find(option => option.label! === machineType);

					if (filterOptionForCurrentType)
						return new KeyValuePairOfStringAndString(filterOptionForCurrentType.value);

					return undefined;
				})
				.filter(keyValuePair => keyValuePair !== undefined) as KeyValuePairOfStringAndString[];
		}

		setFilter(filterSelection)(dispatch);
		applyFilter(filterSelection, sorting, false, selectedMachines)(dispatch);
	};
}

export function setFilter(input: FilterSelections) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetFilterAction({ input }));
	};
}

// Filter was applied by code
export function setAppliedFilter(input: IFilterDto) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetAppliedFilterAction({ input }));
	};
}

// Filter was applied by user
export function setUserAppliedFilter(input: IFilterDto) {
	return (dispatch: Dispatch<Action<{}>>) => {
		dispatch(SetUserAppliedFilterAction({ input }));
	};
}

export function getFilterOptions() {
	return async (dispatch: Dispatch<Action<{}>>) => {
		let opts: FilterOptions = {
			machineGroupOptions: [],
			machineIdOptions: [],
			machineTypeOptions: [],
			postalCodeOptions: [],
			statusOptions: [],
			fillLevelOptions: [],
			wasteTypeOptions: [],
			autoCompleteDropdownOptions: [],
			onlineStatusOptions: [],
			containerSizeOptions: [],
			favouriteOptions: [],
			subscriptionsOptions: [],
		};

		try {
			const filterOptions = await unitClient.getFilterOptions();

			if (filterOptions) {
				filterOptions.unitGroups &&
					filterOptions.unitGroups.forEach(unitGroupOption => {
						opts.machineGroupOptions.push({
							label: localizedDynamic(unitGroupOption),
							value: unitGroupOption,
						});
					});
				filterOptions.unitIds &&
					filterOptions.unitIds.forEach(unitId => {
						opts.machineIdOptions.push({
							label: unitId,
							value: unitId,
						});
					});
				filterOptions.unitModels &&
					filterOptions.unitModels.forEach(unitType => {
						opts.machineTypeOptions.push({
							label: unitType.key,
							value: unitType,
						});
					});
				// let errors: number[] = [];
				// let warnings: number[] = [];
				// let noErrors: number[] = [];
				filterOptions.status &&
					filterOptions.status.forEach(status => {
						opts.statusOptions.push({
							label: localizedDynamic(status),
							value: status,
						});
					});

				filterOptions.fillLevel &&
					[50, 51, 60, 70, 80, 90, 100].forEach(fillLevel => {
						const labelText = `${fillLevel === 50 ? '<= ' + fillLevel : fillLevel !== 100 ? '>= ' + fillLevel : fillLevel
							}%`;
						const doesFillLevelAlreadyExist = opts.fillLevelOptions.some(o => o.label === labelText);
						if (doesFillLevelAlreadyExist) {
							return;
						}

						opts.fillLevelOptions.push({
							label: `${labelText}`,
							value: fillLevel,
						});
					});

				filterOptions.wasteTypes &&
					filterOptions.wasteTypes.forEach(wasteType => {
						opts.wasteTypeOptions.push({
							label: localizedDynamic(wasteType),
							value: wasteType,
						});
					});

				filterOptions.containerSize &&
					filterOptions.containerSize.forEach(containerSize => {
						opts.containerSizeOptions.push({
							label: containerSize + ' m³',
							value: containerSize,
						});
					});

					filterOptions.subscriptions &&
					filterOptions.subscriptions.forEach(subscription => {
						opts.subscriptionsOptions.push({
							label: localizedDynamic(subscription),
							value: subscription,
						});
					});	

				opts.autoCompleteDropdownOptions.push(
					{ label: localized('unitId'), value: 'Machine Id' },
					{ label: localized('PostalCode'), value: 'Postal code' }
				);

				opts.onlineStatusOptions.push(
					{ label: localized('Online'), value: 'Online' },
					{ label: localized('Offline'), value: 'Offline' },
					{ label: localized('NoModem'), value: 'NoModem' }
				);

				opts.favouriteOptions.push(
					{ label: localized('Favourite'), value: true },
					{ label: localized('NotFavourite'), value: false }
				);
			}
		} catch (error: any) {
			notificationService.showErrorMessageWithError((error).toString(), undefined, error);
		}

		dispatch(GetFilterOptionsAction.done({ params: {}, result: opts }));
	};
}
