import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IUserGroupPermission } from 'api/api';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { RouteBasePath, Routes } from 'state/ducks/routes';
import { localized, localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import {
	FilterBarId,
	GMapFillPageId,
	MachineTableId,
	NavBarHeightPx,
	ReportsFilterContainerId,
	ReportsTableContainerId,
	SpecificNotificationContainerId,
	UnitsSelectedRowHeightPx,
} from 'utilities/constants';
import { biConvertNumberToSeparatedString } from 'utilities/number-helpers';
import { tutorialVimeoId } from 'utilities/tutorial-constants';
import { authPermissionCheck } from 'view/components/auth/auth-helper';
import {
	locationPageAuthRequirements,
	notificationPageAuthRequirements,
	reportPageAuthRequirements,
	unitsPageAuthRequirements,
} from 'view/components/auth/auth-requirements';
import AuthRoute from 'view/components/auth/auth-route';
import notificationsList from 'view/components/table/notifications/notification-definition-list/notifications-list';
import {
	NotificationEmailListContainerId,
	calculateNotificationEmailScrollbarHeight,
} from 'view/components/table/notifications/specific-notification-definition/email-configuration';
import { NotSelectorContainerId } from 'view/components/table/notifications/specific-notification-definition/notification-selector';
import SpecificNotification from 'view/components/table/notifications/specific-notification-definition/specific-notification';
import UnitsSelected from 'view/components/units-selected/units-selected';
import VideoButtonDialog from 'view/components/video/video-button-dialog';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import AddNewNotification from 'view/shared/components/notification-utility/add-new-notification';
import NotificationUtility from 'view/shared/components/notification-utility/notification-utility';
import FilterBar from '../components/filter-bar/filter-bar';
import { Location } from '../components/table/location/location';
import LocationsShown from '../components/table/location/location-shown/locations-shown';
import Machines from '../components/table/machines/machines';
import NotificationUnitsFilteredOut from '../components/table/notifications/specific-notification-definition/notifications-filtered-text/notification-units-filtered-out';
import Reports from '../components/table/reports/reports';
import ReportsFilteredOut from '../components/table/reports/reports-filtered-text/reports-filtered-text';
import TableUtility from '../components/table/table-utility/table-utility';
import './home.scss';

const mapStateToProps = (state: AppState) => {
	const offlineUnits = state.tableSettingsReducer.selectedMachines.filter(x =>
		state.machineSelectedReducer.machinesSelected?.some(y => y.id === x && !y.hasSubscription)
	);

	return {
		machinesCount: state.machinesReducer.machineCount,
		selectedMachinesCount: state.tableSettingsReducer.selectedMachines.length,
		reportDataType: state.reportReducer.dataType,
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
		offlineUnitCount: offlineUnits.length,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

interface State {
	unitsSelectedDialogVisible: boolean;
}

const getTableContentRoutes = (userGroupPermissions: IUserGroupPermission[]) => {
	let machineRoutes: string[] = [Routes.Machines];
	if (authPermissionCheck(userGroupPermissions, unitsPageAuthRequirements)) machineRoutes.push(RouteBasePath);

	return (
		// TODO: DASHBOARD. Once dashboard is stable, add this back
		// <Route key="machines" path={Routes.Machines} exact={true} component={Machines} />,
		<Switch>
			<AuthRoute
				key="machines"
				path={machineRoutes}
				exact={true}
				component={Machines}
				authPageRequirement={unitsPageAuthRequirements}
			/>
			<AuthRoute
				key="location"
				path={Routes.Location}
				component={Location}
				authPageRequirement={locationPageAuthRequirements}
			/>
			<AuthRoute
				key="reports"
				path={Routes.Reports}
				component={Reports}
				authPageRequirement={reportPageAuthRequirements}
			/>
			<AuthRoute
				key="notifications"
				path={Routes.Notifications}
				exact={true}
				component={notificationsList}
				authPageRequirement={notificationPageAuthRequirements}
			/>
			<AuthRoute
				key="notifications-edit"
				path={Routes.NotificationsEditWithParam}
				component={SpecificNotification}
				authPageRequirement={notificationPageAuthRequirements}
			/>
		</Switch>
	);
};

class HomePage extends React.PureComponent<Props, State> {
	private observer: MutationObserver | undefined;

	public constructor(props: Props) {
		super(props);

		this.state = { unitsSelectedDialogVisible: false };
	}

	// Sets up responsive filterbar, to set height correctly on underlying components
	public componentDidMount() {
		this.setHeightsObserver();
	}

	public componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	public render() {
		return (
			<BiTitle title={this.getLocalizedPageNameFromUrl()}>
				<div>
					<FilterBar idOfFilterBarParent="home" />
					<div className="relative-shaded">
						<div className="margin-horizontal-responsive flex-direction-row flex-end-column">
							<div className="bi-button-units-selected">
								<BiButton
									onClick={this.handleEditUnitsSelected}
									colorTheme="org-orange"
									containerTheme="slim-with-rounded-corners"
									shouldHighlight={this.props.selectedMachinesCount > 0}
								>
									<span className="flex-wrap flex-center-row">
										<span className="text-bold white-space-pre">
											{localized('UnitsSelected')}:{' '}
										</span>
										<span className="white-space-nowrap">
											{localizedInterpolation(this.props.offlineUnitCount ?'XOfYWithZ' :'XOfY', {
												x: biConvertNumberToSeparatedString(this.props.selectedMachinesCount),
												y: biConvertNumberToSeparatedString(this.props.machinesCount || 0),
												z: biConvertNumberToSeparatedString(this.props.offlineUnitCount || 0),
											})}
										</span>
									</span>
								</BiButton>
								{this.props.location.pathname === Routes.Location && <LocationsShown />}
								{this.props.location.pathname === Routes.Reports && <ReportsFilteredOut />}
								{this.props.location.pathname.includes(Routes.NotificationsEdit) && (
									<NotificationUnitsFilteredOut />
								)}
							</div>
							<div className="margin-left-auto">{this.getRightTopComponent()}</div>
						</div>
					</div>

					{getTableContentRoutes(this.props.userGroupPermissions)}

					<UnitsSelected
						onHide={this.handleHideDialog}
						visible={this.state.unitsSelectedDialogVisible}
						showHeader={false}
					/>
				</div>
			</BiTitle>
		);
	}

	public handleEditUnitsSelected = () => {
		this.setState({ unitsSelectedDialogVisible: true });
	};

	public handleHideDialog = () => {
		this.setState({ unitsSelectedDialogVisible: false });
	};

	private setHeightsObserver() {
		let myElement = document.getElementById(FilterBarId);
		let config = {
			attributes: true,
			childList: true,
			characterData: true,
			subtree: true,
		};

		// This sets the height of the table and map is because we don't know the height of the filterbar
		this.observer = new MutationObserver(mutations => {
			let filterBarElement = document.getElementById(FilterBarId);
			let machineTable = document.getElementById(MachineTableId);
			let gmap = document.getElementById(GMapFillPageId);
			let reportsTableContainer = document.getElementById(ReportsTableContainerId);
			let reportsFilterContainer = document.getElementById(ReportsFilterContainerId);
			let notificationsContainer = document.getElementById(SpecificNotificationContainerId);
			let notSelectorContainer = document.getElementById(NotSelectorContainerId);
			let notificationEmailListContainer = document.getElementById(NotificationEmailListContainerId);

			if (machineTable && filterBarElement) {
				let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
				machineTable.setAttribute('style', `height: calc(100vh - ${height}px)`);
			}
			if (gmap && filterBarElement) {
				let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
				gmap.setAttribute('style', `height: calc(100vh - ${height}px)`);
			}

			if (reportsTableContainer && filterBarElement && reportsFilterContainer) {
				let height =
					filterBarElement.offsetHeight +
					reportsFilterContainer.offsetHeight +
					NavBarHeightPx +
					UnitsSelectedRowHeightPx;

				reportsTableContainer.setAttribute('style', `height: calc(100vh - ${height}px)`);
			}

			if (notificationsContainer && filterBarElement && notSelectorContainer) {
				let height = filterBarElement.clientHeight + NavBarHeightPx + UnitsSelectedRowHeightPx;
				notificationsContainer.setAttribute('style', `min-height: calc(100vh - ${height}px)`);
				notSelectorContainer.setAttribute('style', `min-height: calc(100vh - ${height}px)`);
			}

			calculateNotificationEmailScrollbarHeight(notificationEmailListContainer, filterBarElement, true);
		});

		if (myElement) {
			this.observer.observe(myElement, config);
		}
	}

	private getLocalizedPageNameFromUrl = (): string => {
		const pathName = this.props.location.pathname;
		switch (true) {
			case pathName.startsWith(Routes.Location):
				return localized('location');
			case pathName.startsWith(Routes.Reports):
				return localized('Reports');
			case pathName.startsWith(Routes.Notifications):
				return localized('Title_Notifications');
			case pathName.startsWith(Routes.Machines):
			default:
				return localized('Title_Home');
		}
	};

	private getRightTopComponent = (): JSX.Element => {
		const route = this.props.location.pathname;

		if (route === Routes.Location) {
			return <></>;
		} else if (route === Routes.Reports) {
			return (
				<VideoButtonDialog
					buttonLabel={localized('Tutorial')}
					dialogTitle={localized(tutorialVimeoId.reports.title)}
					vimeoId={tutorialVimeoId.reports.id}
				/>
			);
		} else if (route === Routes.Notifications) {
			return (
				<NotificationUtility>
					<AddNewNotification />
					<VideoButtonDialog
						buttonLabel={localized('Tutorial')}
						dialogTitle={localized(tutorialVimeoId.notifications.title)}
						vimeoId={tutorialVimeoId.notifications.id}
					/>
				</NotificationUtility>
			);
		} else if (route.toLowerCase().includes(Routes.NotificationsEdit)) {
			return (
				<NotificationUtility>
					<VideoButtonDialog
						buttonLabel={localized('Tutorial')}
						dialogTitle={localized(tutorialVimeoId.notifications.title)}
						vimeoId={tutorialVimeoId.notifications.id}
					/>
				</NotificationUtility>
			);
		} else {
			return <TableUtility idOfRefreshRateOnRedux="home" />;
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withAITracking(reactAI.reactPlugin, HomePage, 'Home'));
