import { Sorting } from '@devexpress/dx-react-grid';
import { FilterDto, IFilterDto } from 'api/api';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { applyFilter, getFilterOptions, setFilter, setUserAppliedFilter } from 'state/ducks/filter-search/operations';
import { initialFilterState } from 'state/ducks/filter-search/reducer';
import { getUserGroupsChildHierarchy } from 'state/ducks/groups/operations';
import { clearMachinesLocations } from 'state/ducks/location/operations';
import { getMachinesSelected } from 'state/ducks/machine-selected/operations';
import { clearMachines, getMachineCount, getMachinesFiltered } from 'state/ducks/machines/operations';
import { clearReports, setDatatype } from 'state/ducks/reports/operations';
import { setSelectedMachines } from 'state/ducks/table-settings/operations';
import { localized } from 'state/i18n';
import { FilterSelections } from 'state/models/FilterSelections';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { FilterBarId } from 'utilities/constants';
import { getEmailFromToken } from 'utilities/token-util';
import BiInputText from 'view/shared/components/bi-input-text/bi-input-text';
import clearFilterIcon from '../../../assets/clearfilter.png';
import { BiMultiselect } from '../bi-multiselect/bi-multiselect';
import BiTreeSelect from '../bi-tree-select/bi-tree-select';
import { VIRTUAL_PAGE_SIZE } from '../table/bi-table/bi-table';
import './filter-bar.scss';

const mapStateToProps = (state: AppState) => ({
	filter: state.filterSearchReducer.filter,
	sorting: state.tableSettingsReducer.tableSettingsList.find(
		ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
	)?.sorting,
	filterOptions: state.filterSearchReducer.filterOptions,
	machineCount: state.machinesReducer.machineCount,
	userGroups: state.groupsReducer.groups.find(g => g.id === getEmailFromToken()),
	filterIsSelected: state.filterSearchReducer.filterIsSelected,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	resetDataType: () => setDatatype(undefined)(dispatch),
	clearMachines: () => clearMachines()(dispatch),
	clearMachinesLocations: () => clearMachinesLocations()(dispatch),
	getMachines: async (filter?: IFilterDto, skip?: number, take?: number, sorting?: Sorting) =>
		(await getMachinesFiltered(filter, skip, take, sorting))(dispatch),
	setFilterOptions: (input: FilterSelections) => setFilter(input)(dispatch),
	setUserAppliedFilter: (input: IFilterDto) => setUserAppliedFilter(input)(dispatch),
	getFilterOptions: async () => (await getFilterOptions())(dispatch),
	getMachineCount: async (filter?: IFilterDto) => (await getMachineCount(filter))(dispatch),
	clearReports: () => clearReports()(dispatch),
	getUserGroupsChildHierarchy: async () => (await getUserGroupsChildHierarchy())(dispatch),
	setSelectedMachines: (selection: number[]) => setSelectedMachines(selection)(dispatch),
	getMachinesSelected: async (filter: IFilterDto) => (await getMachinesSelected(filter))(dispatch),
	applyFilter: async (filter: FilterSelections, sorting: Sorting[] | undefined) =>
		await applyFilter(filter, sorting, true)(dispatch),
});

interface OwnProps {
	idOfFilterBarParent: string;
}

interface OnChangeEvent {
	originalEvent: any;
	value: any;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps;
type State = {
	autoCompleteOptionSelected: AutoCompleteDropdownOptions;
	suggestionsMachine?: any[];
	showExtendedFilter: boolean;
	localMachineIdString: string[];
};
type AutoCompleteDropdownOptions = 'Machine id' | 'Postal code';

class FilterBar extends React.PureComponent<Props, State> {
	private imgButtonClassName = 'p-button-custom bi-button-img-only';
	constructor(props: Props) {
		super(props);
		this.state = {
			autoCompleteOptionSelected: 'Machine id',
			suggestionsMachine: undefined,
			showExtendedFilter: false,
			localMachineIdString: this.props.filter.machineIdsSelected ? this.props.filter.machineIdsSelected : [],
		};
	}

	public componentDidMount() {
		this.props.getFilterOptions();
		this.props.getUserGroupsChildHierarchy();
		let filterDto = new FilterDto();
		filterDto.init(this.props.filter);
		if (this.props.filter.machineTypesSelected) {
			filterDto.machineTypesSelected = this.props.filter.machineTypesSelected.map(mts => mts.key!);
		}
		this.props.getMachinesSelected(filterDto);
	}

	public handleClickMachineGroup = (e: OnChangeEvent) => {
		this.props.setFilterOptions({
			...this.props.filter,
			machineGroupsSelected: e.value,
			machineTypesSelected:
				this.props.filter.machineTypesSelected && this.props.filter.machineTypesSelected.length > 0
					? this.props.filter.machineTypesSelected.filter((mtSelected: any) =>
							e.value.includes(mtSelected.value)
					  )
					: [],
		});
	};
	public handleClickMachineId = (e: OnChangeEvent) => {
		this.setState({ localMachineIdString: e.value });
		this.props.setFilterOptions({
			...this.props.filter,
			machineIdsSelected: e.value.length !== 0 ? e.value.split(/[ ,]+/).filter((val: string) => val !== '') : [],
		});
	};

	public handleClickMachineType = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			machineTypesSelected: e.value,
		});

	public handleChangePostalCodeFrom = (e: React.FormEvent<HTMLInputElement>) => {
		this.props.setFilterOptions({
			...this.props.filter,
			postalCodeFrom: e.currentTarget.value.length !== 0 ? e.currentTarget.value : '',
		});
	};

	public handleChangePostalCodeTo = (e: React.FormEvent<HTMLInputElement>) => {
		this.props.setFilterOptions({
			...this.props.filter,
			postalCodeTo: e.currentTarget.value.length !== 0 ? e.currentTarget.value : '',
		});
	};

	public handleClickContainerSize = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			containerSizeSelected: e.value,
		});

	public handleClickStatus = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			statusSelected: e.value,
		});

	public handleClickFillLevelStatus = (e: OnChangeEvent) => {
		this.props.setFilterOptions({
			...this.props.filter,
			fillLevelSelected: e.value !== null ? [e.value] : [],
		});
	};

	public handleClickWasteType = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			wasteTypesSelected: e.value,
		});

	public handleClickOnlineStatusOption = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			onlineStatusSelected: e.value,
		});

	public handleClickIsFavouritOption = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			isFavourite: e.value,
		});

	public handleClickGroup = (e: number[]) => {
		this.props.setFilterOptions({
			...this.props.filter,
			groupIdSelected: e[0],
		});
	};

	public handleClickSubscription = (e: OnChangeEvent) =>
		this.props.setFilterOptions({
			...this.props.filter,
			subscriptionsSelected: e.value,
		});

	public handleClickSortBtn = () => {
		this.setState({ showExtendedFilter: !this.state.showExtendedFilter });
	};

	public handleClickApplyBtn = async () => {
		await this.props.applyFilter(this.props.filter, this.props.sorting);
	};

	public handleClickResetBtn = async () => {
		this.props.resetDataType();
		this.props.clearMachines();
		this.props.clearReports();
		this.props.clearMachinesLocations();
		this.props.setFilterOptions(initialFilterState.filter);
		this.props.setUserAppliedFilter(initialFilterState.appliedFilters);
		this.props.setSelectedMachines([]);

		this.setState({
			localMachineIdString: [],
		});

		this.props.getMachineCount();
		let machinesPromise = this.props.getMachines(
			undefined,
			0,
			VIRTUAL_PAGE_SIZE,
			this.props.sorting && this.props.sorting[0]
		);
		this.props.getMachinesSelected(new FilterDto());
		await machinesPromise;
	};
	public generateAutocompleteSuggestionMachine = (e: { originalEvent: Event; query: string }) => {
		let results: string[] = this.props.filterOptions.machineIdOptions.map(option => option.value);
		results = results.filter(option => {
			return option.toLowerCase().startsWith(e.query.toLowerCase());
		});

		this.setState({ suggestionsMachine: results });
	};

	public render() {
		const buttonClassName = 'p-button-custom bi-button-icon-only';
		const sortButtonClassName = this.props.filterIsSelected && 'org-color';
		const groupsBreadCrumb = this.props.userGroups ? mapTreeItemsToTreeSelectItems(this.props.userGroups.data) : [];
		const extendedFilterClass = this.state.showExtendedFilter
			? 'show-extended-filter-enabled'
			: 'show-extended-filter-disabled';

		return this.props.filterOptions ? (
			<div id={FilterBarId} className="margin-horizontal-responsive flex-direction-row">
				<div className="flex-direction-column">
					<div className={`flex-direction-row margin-top-20px filter-bar-flex-wrap ${extendedFilterClass}`}>
						<BiTreeSelect
							onCheckChanged={this.handleClickGroup}
							onEnterPressed={this.OnEnterPressed}
							nodes={groupsBreadCrumb}
							selectedItems={this.props.filter.groupIdSelected ? [this.props.filter.groupIdSelected] : []}
						/>
						<form onSubmit={this.onFormSubmit} className="input-text-wrapper">
							<AutoComplete
								placeholder={localized('unitId')}
								className={'no-select'}
								value={this.state.localMachineIdString}
								onChange={this.handleClickMachineId}
								suggestions={this.state.suggestionsMachine}
								completeMethod={this.generateAutocompleteSuggestionMachine}
								onBlur={this.removeEnterEventListener}
								onFocus={this.addEnterEventListener}
							/>
							<span className="filter-icon pi pi-search absolute-icon margin-right"></span>
						</form>

						<form onSubmit={this.onFormSubmit}>
							<BiMultiselect
								placeholder={localized('unitType')}
								options={this.props.filterOptions.machineGroupOptions}
								onChange={this.handleClickMachineGroup}
								onEnterPressed={this.OnEnterPressed}
								filter={true}
								selectedItems={this.props.filter.machineGroupsSelected!}
							/>
						</form>
						<form onSubmit={this.onFormSubmit}>
							<BiMultiselect
								placeholder={localized('unitModel')}
								selectedItems={this.props.filter.machineTypesSelected!}
								onEnterPressed={this.OnEnterPressed}
								options={this.props.filterOptions.machineTypeOptions.filter(this.filterUnitModels)}
								onChange={this.handleClickMachineType}
								filter={true}
							/>
						</form>
						<form onSubmit={this.onFormSubmit}>
							<BiMultiselect
								placeholder={localized('unitStatus')}
								selectedItems={this.props.filter.statusSelected!}
								onEnterPressed={this.OnEnterPressed}
								options={this.props.filterOptions.statusOptions}
								onChange={this.handleClickStatus}
							/>
						</form>
					</div>

					{this.state.showExtendedFilter && (
						<>
							<div className="flex-direction-row filter-bar-flex-wrap">
								<BiMultiselect
									placeholder={localized('wasteType')}
									selectedItems={this.props.filter.wasteTypesSelected!}
									onEnterPressed={this.OnEnterPressed}
									options={this.props.filterOptions.wasteTypeOptions}
									onChange={this.handleClickWasteType}
									filter={true}
								/>
								<Dropdown
									showClear={true}
									placeholder={localized('fillLevel')}
									value={
										this.props.filter.fillLevelSelected &&
										this.props.filter.fillLevelSelected.length > 0
											? this.props.filter.fillLevelSelected[0]
											: undefined
									}
									options={this.props.filterOptions.fillLevelOptions}
									className={
										this.props.filter.fillLevelSelected &&
										this.props.filter.fillLevelSelected.length > 0
											? 'yellow'
											: ''
									}
									onChange={this.handleClickFillLevelStatus}
								/>
								<BiMultiselect
									placeholder={localized('onlineStatus')}
									options={this.props.filterOptions.onlineStatusOptions}
									onEnterPressed={this.OnEnterPressed}
									onChange={this.handleClickOnlineStatusOption}
									selectedItems={this.props.filter.onlineStatusSelected!}
								/>
								<div className="flex-center-column">
									<form onSubmit={this.onFormSubmit}>
										<BiInputText
											icon="pi pi-search"
											placeholder={
												!this.props.filter.postalCodeFrom ||
												(this.props.filter.postalCodeFrom &&
													this.props.filter.postalCodeFrom.length === 0)
													? localized('PostalCodeFrom')
													: ''
											}
											value={
												this.props.filter.postalCodeFrom &&
												this.props.filter.postalCodeFrom.length > 0
													? this.props.filter.postalCodeFrom
													: ''
											}
											onChange={this.handleChangePostalCodeFrom}
										/>
									</form>
									<span className="hyphen-divider">-</span>
								</div>
								<form onSubmit={this.onFormSubmit}>
									<BiInputText
										icon="pi pi-search"
										id="PostalCodeTo"
										placeholder={
											!this.props.filter.postalCodeTo ||
											(this.props.filter.postalCodeTo &&
												this.props.filter.postalCodeTo.length === 0)
												? localized('PostalCodeTo')
												: ''
										}
										value={
											this.props.filter.postalCodeTo && this.props.filter.postalCodeTo.length > 0
												? this.props.filter.postalCodeTo
												: ''
										}
										onChange={this.handleChangePostalCodeTo}
									/>
								</form>
							</div>
							<div className="flex-direction-row filter-bar-flex-wrap">
								<BiMultiselect
									placeholder={localized('containerSize')}
									selectedItems={this.props.filter.containerSizeSelected!}
									onEnterPressed={this.OnEnterPressed}
									options={this.props.filterOptions.containerSizeOptions}
									onChange={this.handleClickContainerSize}
									filter={true}
								/>

								<BiMultiselect
									placeholder={localized('Favourite')}
									options={this.props.filterOptions.favouriteOptions}
									onEnterPressed={this.OnEnterPressed}
									onChange={this.handleClickIsFavouritOption}
									selectedItems={this.props.filter.isFavourite!}
								/>
								<BiMultiselect
									placeholder={localized('subscription')}
									selectedItems={this.props.filter.subscriptionsSelected!}
									onEnterPressed={this.OnEnterPressed}
									options={this.props.filterOptions.subscriptionsOptions}
									onChange={this.handleClickSubscription}
									filter={true}
								/>
							</div>
						</>
					)}
				</div>
				<div className="flex-direction-row margin-vertical-20px filter-button-container">
					<Button
						className={this.imgButtonClassName}
						onClick={this.handleClickResetBtn}
						icon="none"
						title={localized('reset')}
					>
						<img src={clearFilterIcon} className="filter-icon-size" alt={localized('reset')} />
					</Button>
					<Button
						title={localized('apply')}
						className={buttonClassName}
						icon="pi pi-check-circle"
						onClick={this.handleClickApplyBtn}
					/>
					<Button
						className={buttonClassName + ' ' + sortButtonClassName}
						icon="pi pi-sort"
						onClick={this.handleClickSortBtn}
					/>
				</div>
			</div>
		) : null;
	}

	private OnEnterPressed = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			this.handleClickApplyBtn();
		}
	};

	private onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		this.handleClickApplyBtn();
	};

	private filterUnitModels = (mt: SelectItem) =>
		this.props.filter.machineGroupsSelected && this.props.filter.machineGroupsSelected.length > 0
			? this.props.filter.machineGroupsSelected.includes(mt.value.value)
			: true;
	private removeEnterEventListener = () => {
		document.removeEventListener('keydown', this.OnEnterPressed);
	};
	private addEnterEventListener = () => {
		document.addEventListener('keydown', this.OnEnterPressed);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
