import { IUnitDetailsDto, IDropdownDataDto } from 'api/api';
import { IdDataTuple } from 'utilities/types-util';

export interface MachineDetailsState {
    machineDetails: IdDataTuple<IUnitDetailsDto, string>[];
    options: IDropdownDataDto;
    reFetchDetails: boolean
}

export enum GenericLevelLimits {
    Low = 33,
    Medium = 67,
    High = 100,
}

export type UnitFavouriteChangeType = {
    unitId: string,
    isFavourite: boolean,
}
