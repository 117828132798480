import { IUserGroupPermission, RolesType } from 'api/api';
import { AuthPageRequirement } from './auth-requirements';
import { BrandTypes, IsBrandBramidan } from 'utilities/brand-utils';
import { hasAtLeast } from 'utilities/roles-util';

export const environmentPermissionCheck = (environmemtSpecific?: BrandTypes): boolean => {
	if (environmemtSpecific === BrandTypes.Bramidan && !IsBrandBramidan) return false;
	if (environmemtSpecific === BrandTypes.Presto && IsBrandBramidan) return false;
	return true;
};

export const authPermissionCheck = (
	userGroupPermissions: IUserGroupPermission[],
	authPageRequirement: AuthPageRequirement,
	hasSubscription: boolean = true
): boolean => {
	// Environment specific check
	if (!environmentPermissionCheck(authPageRequirement.environmemtSpecific)) return false;

	// User must parse one of these validations
	return (
		((authPageRequirement.minRole &&
			userGroupPermissions.some(usr => hasAtLeast(usr.roleType, authPageRequirement.minRole!))) || // User has at least minRole
			(authPageRequirement.exactRoles?.length &&
				userGroupPermissions.some(usr => authPageRequirement.exactRoles!.includes(usr.roleType))) || // At least one role within the list of exactRoles
			(!!authPageRequirement.isPartnerArea && userGroupPermissions.some(usr => usr.isPartnerArea))) && // User isPartnerArea
		(!authPageRequirement.blockIfNoSubscription || (hasSubscription || userGroupPermissions.some(usr => usr.roleType === RolesType.SuperAdmin))) // No subscription block
	);
};
