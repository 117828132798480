import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { AppState } from 'state/store';
import { PreAuthRedirectLocationKey } from 'utilities/constants';
import { authPermissionCheck } from './auth-helper';
import { AuthPageRequirement } from './auth-requirements';
import { FetchFailedCard } from './fetch-failed-card';
import { UnauthorizedCard } from './unauthorized-card';
import bramidanTerms from 'assets/terms-and-conditions/bramidan_terms_and_conditions_V1.pdf';
interface PropsFromParent {}

const mapStateToProps = (state: AppState, props: RouteProps) => {
	const serialNumber = decodeURIComponent((props as any).computedMatch.params.url_id);
	const hasSubscription = state.machinesReducer.machineSubscriptions?.some(x => x.unitId === serialNumber);

	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
		isNotInitializedOrLoading: !state.userPermissionReducer.isInitialized || state.userPermissionReducer.isLoading,
		fetchFailed: state.userPermissionReducer.fetchFailed,
		hasSubscription,
	};
};

type Props = PropsFromParent &
	RouteProps &
	ReturnType<typeof mapStateToProps> & {
		authPageRequirement: AuthPageRequirement;
	};

class AuthRoute extends React.PureComponent<Props> {
	public render() {
		const { path, component, exact, authPageRequirement, hasSubscription } = this.props;

		if (!this.props.isAuthenticated) {
			if (window.location.pathname == Routes.TermsAndconditions) {
				return (
					<object data={bramidanTerms} type="application/pdf" style={{ width: '100vw', height: '100vh' }}>
						<p>
							Unable to display PDF file. <a href={bramidanTerms}>Download</a> instead.
						</p>
					</object>
				);
			}
			localStorage.setItem(PreAuthRedirectLocationKey, history.location.pathname);
			history.replace(Routes.SignInRedirect);
			return null;
		}
		if (this.props.isNotInitializedOrLoading) {
			return null;
		}
		if (authPermissionCheck(this.props.userGroupPermissions, authPageRequirement, hasSubscription)) {
			return (
				<div>
					{exact ? (
						<Route exact={true} path={path} component={component} />
					) : (
						<Route path={path} component={component} />
					)}
				</div>
			);
		}
		if (this.props.fetchFailed) {
			return <FetchFailedCard />;
		} else {
			return <UnauthorizedCard />;
		}
	}
}
export default connect(mapStateToProps)(AuthRoute);
