import { Column, Sorting, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ITableSettings, IUnitsDto } from 'api/api';
import { localized } from 'state/i18n';
import { IsBrandBramidan } from 'utilities/brand-utils';

export interface TableSettingsState {
	currentTableSettingId: number;
	tableSettingsList: TableSettingsList[];
	selectedMachines: number[];
	loaded: boolean;
	timesUserSelectedMachines: number;
}

export interface TableSettingsList {
	columnWidths: TableColumnWidthInfoGeneric<MachineDtoKeys>[];
	columnOrder: MachineDtoKeys[];
	sorting: Sorting[];
	columns: ColumnGeneric<MachineDtoKeys>[];
	columnsLocked: MachineDtoKeys[];
	tableSettings: ITableSettings;
}

export type MachineDtoExtraKeys =
	| 'moreInfo'
	| 'updatedDateFriendly'
	| 'gpsLocation'
	| 'nextService'
	| 'predictedEmptyingDate'
	| 'connectionStatusAsSignalStrength'
	| 'select'
	| 'homeLocation';
export type MachineDtoKeys = keyof IUnitsDto | MachineDtoExtraKeys;

export interface TableColumnWidthInfoGeneric<T extends string> extends TableColumnWidthInfo {
	columnName: T;
}

export interface SortingGeneric<T extends string> extends Sorting {
	columnName: T;
}

export interface ColumnGeneric<T extends string> extends Column {
	name: T;
	title: string;
}

// These are the columns that will be rendered by all users.
export const basicColumns: ColumnGeneric<MachineDtoKeys>[] = [
	{ name: 'select', title: 'Select' },
	{ name: 'connectionStatusAsSignalStrength', title: localized('connectionStatusAsSignalStrength') },
	{ name: 'updatedDateFriendly', title: localized('updatedDateFriendly') },
	{ name: 'lastestChange', title: localized('lastestChange') },
	{ name: 'errorAndSeverities', title: localized('unitStatus') }, // Added
	// { name: "simCardNo", title: localized("simCardNo") },
	{ name: 'devicePhoneNo', title: localized('devicePhoneNo') },
	{ name: 'fillLevel', title: localized('fillLevel') },
	{ name: 'pickupSystem', title: localized('pickupSystem') },
	{ name: 'unitId', title: localized('unitId') },
	{ name: 'gpsLocation', title: localized('gpsLocation') },
	{ name: 'homeLocation', title: localized('HomeLocation') },
	{ name: 'controllerName', title: localized('controllerName') },
	{ name: 'firmwareVersion', title: localized('firmwareVersion') },
	{ name: 'manufacturer', title: localized('manufacturer') },
	{ name: 'unitModel', title: localized('unitModel') },
	{ name: 'customerUnitId', title: localized('customerUnitId') },
	{ name: 'wasteTypeTranslationKey', title: localized('wasteTypeTranslationKey') },
	{ name: 'collector', title: localized('collector') },
	{ name: 'distributor', title: localized('distributor') },
	{ name: 'distributorCountry', title: localized('distributorCountry') },
	{ name: 'groupName', title: localized('groupName') },
	{ name: 'siteUser', title: localized('siteUser') },
	{ name: 'siteDescription', title: localized('siteDescription') },
	{ name: 'siteAddress', title: localized('siteAddress') },
	{ name: 'siteCity', title: localized('siteCity') },
	{ name: 'sitePostalCode', title: localized('sitePostalCode') },
	{ name: 'serviceContractTypeTranslationKey', title: localized('serviceContractType') },
	{ name: 'lastService', title: localized('lastService') },
	{ name: 'nextService', title: localized('nextService') },
	{ name: 'unitsEmptied', title: localized('unitsEmptied') },
	{ name: 'unitsEmptiedThirtyDays', title: localized('unitsEmptiedThirtyDays') },
	// { name: "predictedEmptyingDate", title: localized("predictedEmptyingDate") },
	{ name: 'totalRunningHours', title: localized('totalRunningHours') },
	{ name: 'totalNumberOfCycles', title: localized('totalNumberOfCycles') },
	{ name: 'averageCyclesPerUnitEmptiedThirtyDays', title: localized('averageCyclesPerUnitEmptiedThirtyDays') },
	{ name: 'warrantyExpires', title: localized('warrantyExpires') },
	{ name: 'comments', title: localized('comments') },
	{ name: 'moreInfo', title: localized('moreInfo') },
	{ name: 'ownershipTranslationKey', title: localized('ownership') },
	{ name: 'installationDate', title: localized('installationDate') },
	{ name: 'cyclesSinceLastOutput', title: localized('cyclesSinceLastOutput') },
	{ name: 'pressSeries', title: localized('pressSeries') },
	{ name: 'pressSeriesSinceLastOutput', title: localized('pressSeriesSinceLastOutput') },
	{ name: 'daysSinceInstalled', title: localized('daysSinceInstalled') },
	{ name: 'fullCounterAverage', title: localized('fullCounterAverage') },
	{ name: 'containerSize', title: localized('containerSize') },
	{ name: 'balesOnSite', title: localized('balesOnSite') },
	{ name: 'modemName', title: localized('modemName') },
	{ name: 'trackerBatteryLevel', title: localized('trackerBatteryLevel') },
	{ name: 'subscription', title: localized('subscription') },
	{ name: 'subscriptionLeasingBilling', title: localized('subscriptionLeasingBilling') },
	{ name: 'subscriptionStartDate', title: localized('subscriptionStartDate') },
	{ name: 'subscriptionNextBillingDate', title: localized('subscriptionNextBillingDate') },
	{ name: 'subscriptionEndDate', title: localized('subscriptionEndDate') },
];

//Columns only admins can see
export const adminColumns: ColumnGeneric<MachineDtoKeys>[] = [
	{ name: 'uploadedDate', title: localized('uploadedDate') },
];

const defaultBramidanColumnNames: MachineDtoKeys[] = ['balesOnSite'];
const defaultPrestoColumnNames: MachineDtoKeys[] = [];

const defaultColumnNames: MachineDtoKeys[] = [
	'select',
	'unitId',
	'connectionStatusAsSignalStrength',
	'fillLevel',
	'manufacturer',
	'unitModel',
	'gpsLocation',
	'homeLocation',
	'siteUser',
	'siteAddress',
	'siteCity',
	'moreInfo',
	'unitsEmptied',
	'lastestChange',
	'totalNumberOfCycles',
	'totalRunningHours',
	'errorAndSeverities',
];

if (IsBrandBramidan) {
	defaultColumnNames.push(...defaultBramidanColumnNames);
} else {
	defaultColumnNames.push(...defaultPrestoColumnNames);
}

export const allColumns: ColumnGeneric<MachineDtoKeys>[] = [...basicColumns, ...adminColumns];
export const defaultColumns: ColumnGeneric<MachineDtoKeys>[] = basicColumns.filter(c =>
	defaultColumnNames.includes(c.name)
);


export const limitedDataColumns = {
	lastestChange: null,
	totalNumberOfCycles: null,
	cyclesSinceLastOutput: null,
	pressSeries: null,
	pressSeriesSinceLastOutput: null,
	devicePhoneNo: null,
	containerSize: null,
	latitude: null,
	longitude: null,
	installationDate: null,
	warrantyExpires: null,
	lastService: null,
	nextService: null,
	totalRunningHours: null,
	comments: null,
	signalStrength: null,
	collector: null,
	updatedDateFriendly: null,
	baleCountAtLastPickup: null,
	moreInfo: null,
	wasteTypeTranslationKey: null,
	pickupSystem: null,
	unitsEmptied: null,
	unitsEmptiedThirtyDays: null,
	fillLevel: null,
	averageCyclesPerUnitEmptiedThirtyDays: null,
	balesOnSite: null,
	serviceContractTypeTranslationKey: null,
	errorAndSeverities: null,
	trackerBatteryLevel: null,
	customerUnitId: null,
	daysSinceInstalled: null,
	gpsLocation: null,
	homeLocation: null,
	fullCounterAverage: null,
	homeLatitude: null,
	homeLongitude: null,
	siteUser: null,
	siteAddress: null,
	siteDescription: null,
	siteCity: null,
	sitePostalCode: null,
	connectionStatusAsSignalStrength: null,
	distributorCountry: null,
	distributor: null,
}

export const limitedDataColumnsByCreatedDate = {
	homeLocation: null,
	homeLatitude: null,
	homeLongitude: null,
	siteUser: null,
	siteAddress: null,
	siteDescription: null,
	siteCity: null,
	sitePostalCode: null,
}

export const limitDataForBasicUser = {
	distributorCountry: null,
	distributor: null,
}