import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { FilterState } from './types';
import { FilterDto } from 'api/api';

export const initialFilterState: FilterState = {
	filter: {
		machineGroupsSelected: [],
		machineIdsSelected: [],
		machineTypesSelected: [],
		fillLevelSelected: [],
		postalCodeFrom: '',
		postalCodeTo: '',
		statusSelected: [],
		wasteTypesSelected: [],
		onlineStatusSelected: [],
		containerSizeSelected: [],
		subscriptionsSelected: [],
	},
	filterOptions: {
		machineGroupOptions: [],
		machineIdOptions: [],
		machineTypeOptions: [],
		fillLevelOptions: [],
		postalCodeOptions: [],
		statusOptions: [],
		wasteTypeOptions: [],
		autoCompleteDropdownOptions: [],
		onlineStatusOptions: [],
		groupOptions: [],
		containerSizeOptions: [],
		favouriteOptions: [],
		subscriptionsOptions: []
	},
	appliedFilters: new FilterDto(),
	filterIsSelected: false,
	timesTriggeredByUser: 0,
};

export function filterSearchReducer(state: FilterState = initialFilterState, action: Action): FilterState {
	if (isType(action, actions.SetFilterAction)) {
		// Returning just the stateCopy without spreading it out won't result in any re-render.
		const filter = action.payload.input;
		const keys = Object.keys(initialFilterState.filter);
		let filterIsSelected = false;
		keys.forEach(key => {
			if (Array.isArray(filter[key])) {
				if (filter[key].length > 0) {
					filterIsSelected = true;
				}
			}
		});
		return {
			...state,
			filter,
			filterIsSelected,
		};
	}
	if (isType(action, actions.GetFilterOptionsAction.done)) {
		return {
			...state,
			filterOptions: action.payload.result,
		};
	}
	if (isType(action, actions.SetUserAppliedFilterAction)) {
		return {
			...state,
			appliedFilters: action.payload.input,
			timesTriggeredByUser: ++state.timesTriggeredByUser,
		};
	}

	if (isType(action, actions.SetAppliedFilterAction)) {
		return {
			...state,
			appliedFilters: action.payload.input,
		};
	}
	return state;
}
