import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigateToSpecificNotification } from 'state/ducks/notifications/operations';
import { localized, localizedInterpolation, localizedInterpolationDynamic } from 'state/i18n';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from '../dialogs/bi-text-dialog/bi-text-dialog';
import { selectOfflineMachinesCount } from 'state/ducks/machines/reducer';
import { AppState } from 'state/store';
import i18next from 'i18next';

const AddNewNotification: React.FC = () => {
	const dispatch = useDispatch();
	const offlineCount = useSelector((state: AppState) => selectOfflineMachinesCount(state));
	const [showDialog, setShowDialog] = React.useState<boolean>(false);
	const addNewNotification = () => {
		if (offlineCount > 0 && !showDialog) {
			setShowDialog(true);
		} else {
			navigateToSpecificNotification(undefined, true)(dispatch);
		}
	};
	const hideDialog = () => {
		setShowDialog(false);
		navigateToSpecificNotification(undefined, true)(dispatch);
	};

	return (
		<BiButton onClick={addNewNotification} colorTheme="org-primary" containerTheme="normal-button-corners">
			<span className="flex-wrap flex-center-row">
				<span className="white-space-nowrap text-bold">{localized('AddNewNotification')}</span>
			</span>
			<BiTextDialog
				onHide={hideDialog}
				visible={showDialog}
				title={localizedInterpolationDynamic('XMachinesWithoutSubscription', {
					count: offlineCount,
				})}
			>
				<div className="">{localized('BuyMachineSubscriptionForNotification')}</div>
				<div className="flex-center-row margin-top-10px">
					<BiButton colorTheme="org-primary" containerTheme="normal-button-corners" onClick={hideDialog}>
						{localized('Ok')}
					</BiButton>
				</div>
			</BiTextDialog>
		</BiButton>
	);
};

export default AddNewNotification;
