import BiTooltip from './bi-tooltip/bi-tooltip';

import { localized } from 'state/i18n';

import React, { useCallback, useEffect, useMemo } from 'react';

import { UnitDetailsDto } from 'api/api';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitDetails, SetTemporarySubscription } from 'state/ducks/machine-details/operations';
import { AppState } from 'state/store';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import { getIcon } from '../utils/icon-helper';
import { use } from 'i18next';
import { useAppDispatch } from 'utilities/hooks';
import { usePrevious } from 'utilities/use-previous';
import { ConfirmDialog } from './dialogs/bi-dialog-confirm/dialog-confirm';
import { Has2gModem } from 'utilities/subscription-helper';
import { isAtLeastAdminFromState } from 'utilities/roles-util';
import ReActivate from 'assets/re-activate.png';

interface Props {
	className?: string;
	machineDetailsId: string;
}

export const TempSubscriptionButton: React.FC<Props> = ({ className, machineDetailsId }) => {
	const { machineDetails, reFetchDetails } = useSelector((state: AppState) => state.machineDetailsReducer);
	const userLanguage = useSelector((state: AppState) => state.userSettingReducer.userSettings.selectedLanguage);
	const currentMachine = useMemo(
		() => getMachineFromIdDataMapOrDefault(machineDetails, machineDetailsId, UnitDetailsDto),
		[machineDetails, machineDetailsId]
	);
	const isAdmin = useSelector((state: AppState) => isAtLeastAdminFromState(state, currentMachine.data.ownerGroupId));
	const previousReFetchDetails = usePrevious(reFetchDetails, false);
	const [willActivateShortly, setWillActivateShortly] = React.useState<boolean>(false);
	const [showDialog, setShowDialog] = React.useState<boolean>(false);
	const dispatch = useAppDispatch();

	// Set temp subscription date
	const SetTemporarySubscriptionDate = useCallback(async () => {
		SetTemporarySubscription(currentMachine.data.id)(dispatch);
		setWillActivateShortly(true);
	}, [dispatch]);

	// Refetch unitDetails when reFetchDetails is true
	useEffect(() => {
		const fetchData = async () => {
			(await getUnitDetails(currentMachine.id, userLanguage))(dispatch);
		};

		if (!reFetchDetails && previousReFetchDetails && currentMachine) {
			fetchData();
		}
	}, [dispatch, reFetchDetails, userLanguage, currentMachine.id, previousReFetchDetails]);

	const showConfirmDialog = useCallback(() => {
		setShowDialog(true);
	}, []);

	const hideConfirmDialog = useCallback(() => {
		setShowDialog(false);
	}, []);

	// If machine has subscription or tempSubscriptionEndDate, don't show the button
	if (willActivateShortly && (currentMachine.data.hasSubscription || currentMachine.data.tempSubscriptionEndDate)) {
		return (
			<div className=" color-green-dark font-size-normal text-bold ">{localized('SubscriptionActivated')}</div>
		);
	}

	// Pending message
	if (willActivateShortly) {
		return <div className="disabled-text font-size-normal">{localized('WillActivateShortly')}</div>;
	}

	// Subscription went wrong message
	if (currentMachine.data.tempSubscriptionEndDate && !currentMachine.data.hasSubscription) {
		return (
			<div className=" font-size-normal white-space-pre-line color-red font-italic">
				{localized('SubscriptionWentWrong')}
			</div>
		);
	}

	// If machine has subscription or tempSubscriptionEndDate, don't show the button
	if (!isAdmin || currentMachine.data.hasSubscription || currentMachine.data.tempSubscriptionEndDate) {
		return null;
	}

	return (
		<>
			<BiTooltip overlay={localized('ActivateSubscription')}>
				<div className={`${className} cursor-pointer`} onClick={showConfirmDialog}>
					<img src={ReActivate} alt={'ReActivate'} className='re-activate-icon'/>
				</div>
			</BiTooltip>
			<ConfirmDialog
				maskClassName="white-space-pre-wrap"
				onHide={hideConfirmDialog}
				onCancel={hideConfirmDialog}
				onConfirm={SetTemporarySubscriptionDate}
				header={localized('ActivateSubscriptionTooltip')}
				dialogMessage={localized('ActivateSubscriptionContent')}
				confirmColor={'org-primary'}
				visible={showDialog}
			>
				{Has2gModem(currentMachine.data) &&<div className="color-red"> {localized('ActivateSubscription2GWarning')}</div>}
			</ConfirmDialog>
		</>
	);
};
