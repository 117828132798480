import BiTooltip from './bi-tooltip/bi-tooltip';

import { localized, localizedInterpolationDynamic } from 'state/i18n';

import BiButton from './buttons/bi-button/bi-button';
import React from 'react';
import { Spinner } from 'view/components/spinner/spinner';

import { images } from 'utilities/images';
import BiTextDialog from './dialogs/bi-text-dialog/bi-text-dialog';
import { useSelector } from 'react-redux';
import { selectOfflineMachinesCount } from 'state/ducks/machines/reducer';
import { AppState } from 'state/store';
import { isAtLeastSuperAdminFromState } from 'utilities/roles-util';

export const GetExportButton: React.FC<{ handleExportExcel: () => Promise<void>; loadingState: boolean }> = ({
	handleExportExcel,
	loadingState,
}) => {
	const offlineCount = useSelector((state: AppState) => selectOfflineMachinesCount(state));
	const [showDialog, setShowDialog] = React.useState<boolean>(false);
	const isSuperAdmin = useSelector(isAtLeastSuperAdminFromState);
	const onExportPressed = async () => {
		if (!isSuperAdmin) {
			setShowDialog(true);
		}
		handleExportExcel();
	};

	const hideDialog = () => {
		setShowDialog(false);
	};

	return (
		<div className="align-self-flex-end margin-left-40">
			<BiTooltip overlay={localized('ExportToExcel')}>
				<BiButton
					onClick={onExportPressed}
					disabled={loadingState}
					colorTheme="org-grey"
					containerTheme="icon-sized-with-soft-rounded-corners"
				>
					{loadingState ? (
						<Spinner
							shouldOverlay={false}
							shouldUseAbsolutePositioning={false}
							spinnerSize="spinner-container-1-4th-size"
							useMarginsForCorrectingCentering={false}
						/>
					) : (
						<img className="export-icon" src={images.excel} alt={localized('ExcelFile')} />
					)}
					<BiTextDialog
						onHide={hideDialog}
						visible={showDialog}
						title={localizedInterpolationDynamic('XMachinesWithoutSubscription', {
							count: offlineCount,
						})}
					>
						<div className="">{localized('BuyMachineSubscriptionForReports')}</div>
						<div className="flex-center-row margin-top-10px">
							<BiButton
								colorTheme="org-primary"
								containerTheme="normal-button-corners"
								onClick={hideDialog}
							>
								{localized('Ok')}
							</BiButton>
						</div>
					</BiTextDialog>
				</BiButton>
			</BiTooltip>
		</div>
	);
};
