import { IUnitDetailsDto } from 'api/api';
import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { UpdateMachinesOwnerGroupIdAction } from 'state/ducks/machines/actions';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import { updateMachineWeighingSerialNumber, upsertDataMappedToIdString } from 'utilities/reducer-helpers';
import { updateMachineOwnerGroupIdsInDataTupleDetail } from 'utilities/reducer-helpers-update-machine-ownergroup';
import { IdDataTuple } from 'utilities/types-util';
import * as actions from './actions';
import { MachineDetailsState } from './types';

export const initialState: MachineDetailsState = {
	machineDetails: [],
	reFetchDetails: false,
	options: { ownerShips: [], serviceContractTypes: [], pickupSystems: [], wasteTypes: [], manufacturers: [] },
};

export function machineDetailsReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetMachineDetailsAction.done)) {
		const idDataTupleArray = upsertDataMappedToIdString(
			action.payload.result.unitId!,
			action.payload.result,
			state.machineDetails
		);
		return {
			...state,
			machineDetails: idDataTupleArray
		};
	}

	else if (isType(action, actions.UpdateIsFavouriteAction)) {
		const currentObj = state.machineDetails.find(md => md.id === action.payload.unitId)?.data;
		if (!currentObj) return state;

		const updatedUnitDetail: IUnitDetailsDto = { ...currentObj, isFavourite: action.payload.isFavourite };
		const idDataTupleArray = upsertDataMappedToIdString(action.payload.unitId, updatedUnitDetail, state.machineDetails);
		return {
			...state,
			machineDetails: idDataTupleArray,
		};
	}


	else if (isType(action, UpdateMachinesOwnerGroupIdAction.done)) {
		return {
			...state,
			machineDetails: updateMachineOwnerGroupIdsInDataTupleDetail(
				state.machineDetails,
				action.payload.params.MachineIds,
				action.payload.params.destinationGroupId
			)
		};
	}
	else if (isType(action, actions.putMachineDetailsAction.started)) {
		if (action.payload.unitId) {
			const idDataTupleArray = upsertDataMappedToIdString(action.payload.unitId, action.payload, state.machineDetails);
			return {
				...state,
				machineDetails: idDataTupleArray,
			};
		}
	}
	else if (isType(action, actions.putMachineDetailsAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		const idDataTupleArray = upsertDataMappedToIdString(
			action.payload.result.unitId!,
			action.payload.result,
			state.machineDetails)

		return {
			...state,
			machineDetails: idDataTupleArray
		}
	}
	else if (isType(action, actions.GetDropdownOptions.done)) {
		// Returning just the stateCopy without spreading it out won't result in any re-render.
		return {
			...state,
			options: action.payload.result
		};
	} else if (isType(action, actions.UpdateMachineWeighingSerialNumberAction.done)) {
		const idDataTupleArray = updateMachineWeighingSerialNumber(
			state.machineDetails,
			action.payload.params.unitId,
			action.payload.params.weighingSerialNumber
		);

		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			machineDetails: idDataTupleArray,
		};
	}

	else if (isType(action, actions.UpsertBaleStorageAction.done)) {
		return {
			...state,
			machineDetails: state.machineDetails.map(md => !action.payload.result.unitIds?.includes(md.data.id) ? md : {
				...md, data: {
					...md.data,
					balesOnSite: action.payload.result.balesOnSite,
					baleStorageDevices: action.payload.result.unitIds,
					baleStorageId: action.payload.result.baleStorageId,
					automaticBaleCountReset: action.payload.result.calculateAutomatically,
					baleStorageSize: action.payload.result.storageSize
				}
			})
		}
	}
	else if (isType(action, actions.setTemporarySubscription.started)) {
		return {
			...state,
			reFetchDetails: true,
		};

	}
	else if (isType(action, actions.setTemporarySubscription.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			reFetchDetails: false,
		};

	}
	else if (isType(action, actions.setTemporarySubscription.failed)) {
		return {
			...state,
			reFetchDetails: false,
		};
	}

	return state;
}
